export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Lucru în echipă, eficiență ridicată și pragmatism, inovație pozitivă, relaxat și armonios</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Valorile de bază ale Shopify Outlets sunt, de asemenea, o reflectare a spiritului nostru antreprenorial proactiv. Suntem o platformă antreprenorială nou-nouță care nu necesită fonduri uriașe, studii superioare sau antecedente de telefon mobil, astfel încât să puteți începe o afacere și să fiți șeful acasă.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Platforma Shopify Outlets oferă tuturor oportunități de afaceri</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Până la sfârșitul anului 2022, volumul maxim al tranzacțiilor într-o singură zi va atinge 3,24 miliarde USD, creând un total de 2,365 milioane locuri de muncă directe și oportunități de angajare deplină.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Platforma Shopify Outlets a pregătit o scenă grozavă pentru toți cei care doresc să înceapă să folosească comerțul electronic, reunind pe toți de la alimente, îmbrăcăminte, locuințe și toate categoriile sociale pentru a ne realiza visul de a începe o afacere și de a ne îmbogăți. Bill Gates, cel mai bogat om din lume, a spus că secolul 21 va fi fie comerț electronic, fie nicio afacere. Li Ka-shing, un bogat om de afaceri din Hong Kong, a spus că comerțul electronic este o industrie nouă și fiecare oportunitate de afaceri care va apărea va crea un grup de oameni bogați. În același timp, experții Heke au subliniat că, din perspectiva modelului de afaceri, cumpărăturile online globale vor fi în cele din urmă unificate și combinate și vor unifica și integra modele de dezvoltare online și offline.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Platforma de cumpărături Shopify Outlets integrează modelul B2C, modelul C2C și modelele de comerț electronic online și offline O2O. Este o platformă de comerț electronic cuprinzătoare la scară largă, care integrează comercianții pentru vânzări de produse și cumpărături online servicii de diseminare, platforma avansată de cumpărături online de comerț electronic transfrontalier.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">În prezent, grupul global de consumatori a ajuns la 80 de milioane de oameni. Acum planul principal al companiei noastre este să extindă piața globală, așa că în prezent există mult loc de creștere, așa că trebuie să recrutăm un număr mare de oameni care au ideea. de deschidere a unui magazin pentru a se alătura magazinului nostru. Platforma de cumpărături Shopify Outlets invită cu sinceritate membrii comercianților și persoane din întreaga lume să ni se alăture, să pună în comun înțelepciunea altora și să ne realizeze visele comune. Indiferent dacă începeți deja o afacere sau v-ați rătăcit, platforma de mall Shopify Outlets vă va oferi o etapă pentru a vă realiza visele antreprenoriale iti ureaza o cariera de succes!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Cum să devii comerciant Shopify Outlets și să începi o afacere </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Mai întâi trebuie să descarcăm software-ul Shopify Outlets prin App Store pe iPhone sau Google Play. După ce descărcarea este completă, alegeți metoda de înregistrare care vi se potrivește și înregistrați-vă un cont Acum, să facem clic pe Merchant Center pentru a vedea cum să deveniți comerciant. Accesați Merchant Center, apoi faceți clic pe Adăugați produse din Warehouse de către furnizori. Puteți selecta produsele pe care doriți să le vindeți, apoi faceți clic pe „Adăugați în magazinul dvs. pentru vânzare” cu un singur clic, iar magazinul dvs. este gata de deschidere. Magazinul dvs. este gata de deschidere. Puteți să vă decorați magazinul pe platformă. În calitate de comerciant Shopify Outlets, trebuie să avem grijă de clienții care vin la magazinul nostru și îi servesc bine, altfel pot apărea reclamații care afectează scorul de credit al magazinului și penalitățile.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Procesul de a face bani pentru a deveni comerciant în Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Rolurile jucate de Shopify Outlets, furnizori, comercianți și, respectiv, consumatori</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets oferă transportatori de platformă</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Furnizorii furnizează produse Shopify Outlets</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Comercianții deschid magazine în Shopify Outlets și publică produse de la furnizori pe platformă în propriile magazine.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Consumatorii își văd produsele preferate în magazinele comerciale prin reclame și trafic, apoi fac achiziții.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. Comerciantul vede comanda de bunuri și apoi achiziționează produsele corespunzătoare de pe platformă</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Furnizorul livreaza automat marfa conform comenzii.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Când consumatorul primește bunurile, Shopify Outlets va deconta plata către comerciant, iar comerciantul va obține un profit.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify OutletsWish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets promite calitatea oricărui produs achiziționat de consumatori și asigură că toți comercianții vor avea o experiență foarte bună după stabilirea lor. Shopify Outlets va deveni un alt nou tip de gigant transfrontalier al comerțului electronic din lume și va deveni cel mai mare export. Platformă de comerț electronic B2C. Cu cât participați mai devreme ca vânzător de comerț electronic, cu atât vă este mai ușor să câștigați bani. Shopify Outlets salută antreprenorii de comerț electronic din străinătate să se alăture. A alege Shopify Outlets înseamnă a alege bogăția.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Bine, cele de mai sus sunt introducerea afacerii, începe-ți călătoria antreprenorială și acționează rapid!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
