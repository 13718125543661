<template>
  <div class="help-center-y2023" data-spm="14">
    <div class="jekks">
      <div class="jeksld">
        <div class="">
          <p style="font-size: 12px">
            ShopifyOutlets Business Operation Management Limited (07488617) is a credit broker authorised and regulated by the Financial Conduct Authority and works exclusively with lender Home Retail Group CardServices Limited trading as ShopifyOutlets Business Financial Services, which is
            authorised and regulated by the Financial Conduct Authority to provide credit services. Home Retail GroupCard Services Limited is registered in England (04007072) at 33 Holborn, London, England, EC1N 2HT.
          </p>
          <div class="eksje">ShopifyOutlets Business© Limited 2022-2024 All rights reserved</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {
    onGoUrl(type) {
      console.log(type)
      if (type === 'android') {
        let url = 'https://wap.shopifyoutlets.com/#/pages/download/android'
        window.open(url, '_blank')
      } else if (type === 'ios') {
        let url = 'https://wap.shopifyoutlets.com/#/pages/download/iphone'
        window.open(url, '_blank')
      }
    }
  }
}
</script>

<!-- <style scoped src="@/static/css5/index/footer.css"></style> -->
<style>
.jekks {
  padding: 10px;
  background-color: #000;
  color: #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: fixed;
  bottom: 0;
  width: 100%; */
}

.jeksld {
  width: 1600px;
}

.eksje {
  font-size: 13px;
}
</style>
<style scoped>
.site-main-box {
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.container-box {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  width: 1200px;
  display: flex;
}

.site-box {
  float: left;
  padding: 15px 0;
  text-align: center;
  position: relative;
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.site-box:hover {
  background: #f4f4f4;
}

.site-box h3 {
  color: #000000;
  font-weight: 700;
  font-size: 15px;
}

.container-box:first-child {
  border-left: 1px solid #f4f4f4;
}

.site-box {
  border-right: 1px solid #f4f4f4;
}

.site-box .i-great {
  /* background: url('@/static/index/4.png') no-repeat 0 0; */
  background-size: cover;
}

.site-box .icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._be_seller {
  display: inline-block;
  background-image: linear-gradient(94deg, var(--main-color1), var(--main-color2));
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  padding: 5px 15px;
}

.c_flex {
  display: flex;
}

.app-download {
  position: absolute;
  width: 170px;
  height: 170px;
  z-index: 10;
  bottom: 100%;
  border: 2px solid #ff5722;
  display: none;
}
.app-download img {
  width: 100%;
  height: 100%;
}
.a-download {
  flex: 1;
  position: relative;
}
.a-download:hover .app-download {
  display: block;
}

.xx-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-bottom {
  font-size: 12px;
  padding: 22px 20px;
  color: #6b6b6b;
}

.bwddd {
  width: 220px;
}
</style>
