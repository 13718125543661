export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Takım çalışması, yüksek verimlilik ve pragmatizm, pozitif yenilik, rahat ve uyumlu</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets&#39;in temel değerleri aynı zamanda proaktif girişimci ruhumuzun bir yansımasıdır. Biz, büyük fonlar, yüksek eğitim veya cep telefonu geçmişi gerektirmeyen, bir iş kurup evinizde patron olabileceğiniz yepyeni bir girişimcilik platformuyuz.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets platformu herkese iş fırsatları sunuyor</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2022&#39;nin sonunda, tek günlük en yüksek işlem hacmi 3,24 milyar ABD dolarına ulaşacak ve bu da toplam 2,365 milyon doğrudan iş ve tam istihdam fırsatı yaratacak.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets platformu, e-ticareti kullanmaya başlamak isteyen herkese büyük bir sahne hazırlayarak, iş kurma ve zengin olma hayalimizi gerçekleştirmek için gıdadan giyime, konuttan toplumun her kesimine kadar herkesi bir araya getirdi. Dünyanın en zengin adamı Bill Gates, 21. yüzyılın ya e-ticaretle geçeceğini ya da hiç iş yapılmayacağını söyledi. Hong Konglu zengin bir işadamı olan Li Ka-shing, e-ticaretin yeni bir endüstri olduğunu ve ortaya çıkan her iş fırsatının bir grup zengin insan yaratacağını söyledi. Aynı zamanda Heke uzmanları, iş modeli perspektifinden bakıldığında küresel çevrimiçi alışverişin eninde sonunda birleştirilip birleştirileceğini ve sonunda çevrimiçi ve çevrimdışı geliştirme modellerini birleştirip entegre edeceğini belirtti.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets alışveriş platformu, B2C modeli, C2C modeli ve O2O çevrimiçi ve çevrimdışı e-ticaret modellerini entegre eder. Ürün satışları ve çevrimiçi alışveriş için satıcıları entegre eden büyük ölçekli kapsamlı bir e-ticaret platformudur. Çevrimiçi ve çevrimdışı reklam bilgilerini entegre eder. Avrupa&#39;nın gelişmiş sınır ötesi e-ticaret çevrimiçi alışveriş platformu.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Şu anda küresel tüketici grubu 80 milyon kişiye ulaştı. Artık şirketimizin ana planı küresel pazarı genişletmek, dolayısıyla büyüme için çok fazla alan var, bu nedenle bu fikre sahip çok sayıda insanı işe almamız gerekiyor. Mağazamıza katılmak için bir mağaza açmanın. Shopify Outlets alışveriş platformu, dünyanın her yerinden tüccar üyelerini ve bireyleri bize katılmaya, başkalarının bilgeliğini bir araya getirmeye ve ortak hayallerimizi gerçekleştirmeye içtenlikle davet ediyor. İster zaten bir iş kuruyor olun ister yolunuzu kaybetmiş olun, Shopify Outlets alışveriş merkezi platformu size girişimcilik hayallerinizi gerçekleştirmeniz için bir aşama sağlayacaktır. Şirket, e-ticaretle ilgilenen kişileri Shopify Outlets alışveriş merkezi platformuna katılmaya sıcak bir şekilde davet ediyor. Size başarılı bir kariyer diliyorum!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Nasıl Shopify Outlets satıcısı olunur ve iş kurulur </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Öncelikle Shopify Outlets yazılımını iPhone veya Google Play&#39;deki App Store aracılığıyla indirmemiz gerekir. İndirme işlemi tamamlandıktan sonra, size uygun olan kayıt yöntemini seçin ve bir hesap açın. Platformdaki mağazayı bulun ve kayıt olun. Platform incelenip onaylandıktan sonra, gerçek bilgilerinizi doldurup başvuruyu gönderebilirsiniz. Şimdi nasıl satıcı olabileceğinizi görmek için Merchant Center&#39;a tıklayalım. Ürünlerinizi satmaya nasıl başlayabilirsiniz? Merchant Center&#39;a girin, ürün listesini tıklayın ve ardından Depodan Ürün Ekle&#39;yi tıklayın. tedarikçiler tarafından. Satmak istediğiniz ürünleri seçtikten sonra tek tıkla &quot;Satılık olarak mağazanıza ekleyin&quot; seçeneğine tıkladığınızda mağazanız açılmaya hazır hale gelir. Mağazanız açılmaya hazır. Platformda mağazanızı dekore edebilirsiniz, sattığınız malların sipariş fiyatı tedarikçi tarafından belirlenir ve yalnızca platformda sattığınız malların fiyatına göre satış yapabilirsiniz. Shopify Outlets satıcısı olarak mağazamıza gelen müşterilerle iyi ilgilenmemiz ve onlara iyi hizmet vermemiz gerekiyor, aksi takdirde mağazanın kredi puanını ve cezalarını etkileyen şikayetler söz konusu olabilir.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets&#39;te satıcı olmanın para kazanma süreci</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Sırasıyla Shopify Outlet&#39;lerin, tedarikçilerin, satıcıların ve tüketicilerin oynadığı roller</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets platform taşıyıcıları sağlar</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Tedarikçiler Shopify Outlet&#39;lere ürün sağlar</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Satıcılar Shopify Outlets&#39;te mağazalar açar ve platformdaki tedarikçilerin ürünlerini kendi mağazalarında yayınlar.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Tüketiciler en sevdikleri ürünleri ticari mağazalarda reklamlar ve trafik aracılığıyla görür ve ardından satın alma işlemini gerçekleştirir.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. Satıcı mal siparişini görür ve ardından ilgili ürünleri platformda satın alır</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Tedarikçi, malları siparişe göre otomatik olarak gönderir.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Tüketici malları aldığında Shopify Outlets ödemeyi satıcıya yapacak ve satıcı kâr elde edecektir.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Satış NoktalarıDilek</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets, tüketiciler tarafından satın alınan her ürünün kalitesini vaat eder ve tüm satıcıların yerleştikten sonra çok iyi bir deneyim yaşamasını sağlar. Shopify Outlets, dünyadaki yeni bir sınır ötesi e-ticaret devi türü haline gelecek ve en büyük ihracata dönüşecek B2C e-ticaret platformu. E-ticaret satıcısı olarak ne kadar erken katılırsanız para kazanmanız o kadar kolay olur. Shopify Outlets, yurtdışındaki e-ticaret girişimcilerini katılmaya davet ediyor Shopify Outlets&#39;i seçmek, zenginliği seçmek anlamına gelir.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Tamam, yukarıdaki iş tanıtımıdır, girişimcilik yolculuğunuza başlayın ve hızlı hareket edin!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
