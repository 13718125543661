export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Teamwerk, hoge efficiëntie en pragmatisme, positieve innovatie, ontspannen en harmonieus</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">De kernwaarden van Shopify Outlets zijn ook een weerspiegeling van onze proactieve ondernemerszin. Wij zijn een gloednieuw ondernemersplatform waarvoor geen enorme bedragen, een hoge opleiding of een mobiele telefoonachtergrond nodig zijn, zodat u een bedrijf kunt starten en thuis de baas kunt zijn.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Het Shopify Outlets-platform biedt zakelijke kansen voor iedereen</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Tegen eind 2022 zal het maximale transactievolume op één dag 3,24 miljard dollar bereiken, wat in totaal 2,365 miljoen directe banen en volledige werkgelegenheidskansen zal opleveren.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Het Shopify Outlets-platform heeft een groots podium gecreëerd voor iedereen die e-commerce wil gaan gebruiken, door iedereen, van voedsel, kleding, huisvesting en alle lagen van de bevolking, samen te brengen om onze droom te verwezenlijken: een bedrijf starten en rijk worden. Bill Gates, de rijkste man ter wereld, zei dat de 21e eeuw óf e-commerce óf helemaal geen handel zal zijn. Li Ka-shing, een rijke zakenman uit Hong Kong, zei dat e-commerce een nieuwe industrie is, en dat elke zakelijke kans die zich voordoet een groep rijke mensen zal creëren. Tegelijkertijd wezen Heke-experts erop dat vanuit het perspectief van het bedrijfsmodel mondiaal online winkelen uiteindelijk verenigd en gecombineerd zal worden, en uiteindelijk online en offline ontwikkelingsmodellen zal verenigen en integreren.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Het Shopify Outlets-winkelplatform integreert het B2C-model, het C2C-model en O2O online en offline e-commercemodellen. Het is een grootschalig, uitgebreid e-commerceplatform dat verkopers integreert voor productverkoop en online winkelen. Het integreert online en offline advertentie-informatie verspreiding van gemaksdiensten. Europa&#39;s geavanceerde grensoverschrijdende online winkelplatform voor e-commerce.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Op dit moment heeft de wereldwijde consumentengroep 80 miljoen mensen bereikt. Het belangrijkste plan van ons bedrijf is om de wereldmarkt uit te breiden, dus er is momenteel veel ruimte voor groei, dus we moeten een groot aantal mensen rekruteren die dit idee hebben. van het openen van een winkel om lid te worden van onze winkel. Het Shopify Outlets-winkelplatform nodigt ondernemersleden en individuen van over de hele wereld oprecht uit om zich bij ons aan te sluiten, de wijsheid van anderen te bundelen en onze gemeenschappelijke dromen te verwezenlijken. Of u nu al een bedrijf start of de weg kwijt bent, het Shopify Outlets winkelcentrumplatform biedt u een podium om uw ondernemersdromen te verwezenlijken. Het bedrijf verwelkomt mensen die geïnteresseerd zijn in e-commerce van harte om zich aan te sluiten bij het Shopify Outlets winkelcentrumplatform wenst je een succesvolle toekomst!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Hoe u een Shopify Outlets-handelaar kunt worden en een bedrijf kunt starten </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Eerst moeten we de Shopify Outlets-software downloaden via de App Store op iPhone of Google Play. Nadat het downloaden is voltooid, kiest u de registratiemethode die bij u past en registreert u een account. Zoek de winkel op het platform en registreer. Klik om uw echte gegevens in te vullen en de aanvraag in te dienen. Nadat het platform is beoordeeld en goedgekeurd, kunt u inloggen Laten we nu op het Merchant Center klikken om te zien hoe u een verkoper kunt worden. Hoe kunt u beginnen met het verkopen van uw producten? Ga naar het Merchant Center, klik op de productlijst en klik vervolgens op Producten uit magazijn toevoegen door leveranciers. U kunt de producten selecteren die u wilt verkopen en vervolgens met één klik op &quot;Toevoegen aan uw winkel te koop&quot; klikken, en uw winkel is klaar om te openen. Uw winkel is klaar om te openen. U kunt uw winkel op het platform inrichten. De bestelprijs van de goederen die u verkoopt, wordt bepaald door de leverancier en u kunt ze alleen verkopen op basis van de prijs van de goederen die u op het platform verkoopt. Als Shopify Outlets-handelaar moeten we zorgen voor de klanten die naar onze winkel komen en hen goed bedienen, anders kunnen er klachten zijn die van invloed zijn op de kredietscore en boetes van de winkel.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Het proces om geld te verdienen als je verkoper wordt in Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">De rollen die respectievelijk Shopify Outlets, leveranciers, verkopers en consumenten spelen</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets biedt platformaanbieders</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Leveranciers leveren producten aan Shopify Outlets</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Merchants openen winkels in Shopify Outlets en publiceren producten van leveranciers op het platform naar hun eigen winkels.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Consumenten zien hun favoriete producten in winkels via advertenties en verkeer, en doen vervolgens aankopen.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. De handelaar ziet de goederenbestelling en koopt vervolgens de bijbehorende producten op het platform</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. De leverancier verzendt de goederen automatisch volgens de bestelling.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Wanneer de consument de goederen ontvangt, regelt Shopify Outlets de betaling aan de handelaar en maakt de handelaar winst.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify OutletsWish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets belooft de kwaliteit van elk product dat door consumenten wordt gekocht en zorgt ervoor dat alle verkopers een zeer goede ervaring zullen hebben nadat ze zich hebben gevestigd. Shopify Outlets zal een nieuw soort grensoverschrijdende e-commercegigant ter wereld worden en uitgroeien tot de grootste export B2C e-commerceplatform. Hoe eerder u deelneemt als e-commerceverkoper, hoe gemakkelijker het is om geld te verdienen. Shopify Outlets verwelkomt buitenlandse e-commerceondernemers om zich aan te sluiten. Kiezen voor Shopify Outlets betekent kiezen voor rijkdom.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Oké, het bovenstaande is de zakelijke introductie, begin je ondernemersreis en handel snel!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
