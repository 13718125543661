export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Trabalho em equipe, alta eficiência e pragmatismo, inovação positiva, descontraída e harmoniosa</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Os valores fundamentais do Shopify Outlets também são um reflexo do nosso espírito empreendedor proativo. Somos uma plataforma empreendedora totalmente nova que não requer grandes fundos, ensino superior ou experiência em telefonia móvel, para que você possa começar um negócio e ser o chefe em casa.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">A plataforma Shopify Outlets traz oportunidades de negócios para todos</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Até ao final de 2022, o volume máximo de transações num único dia atingirá 3,24 mil milhões de dólares, criando um total de 2,365 milhões de empregos diretos e oportunidades de pleno emprego.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">A plataforma Shopify Outlets preparou um grande palco para todos que desejam começar a usar o e-commerce, reunindo desde alimentação, roupas, moradia e todas as esferas da vida para realizar nosso sonho de começar um negócio e enriquecer. Bill Gates, o homem mais rico do mundo, disse que o século 21 será de comércio eletrônico ou de nenhum negócio. Li Ka-shing, um rico empresário de Hong Kong, disse que o comércio eletrónico é uma indústria nova e que cada oportunidade de negócio que surgir criará um grupo de pessoas ricas. Ao mesmo tempo, os especialistas da Heke salientaram que, do ponto de vista do modelo de negócios, as compras online globais acabarão por ser unificadas e combinadas, e acabarão por unificar e integrar modelos de desenvolvimento online e offline.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">A plataforma de compras Shopify Outlets integra modelo B2C, modelo C2C e modelos de comércio eletrônico online e offline O2O. É uma plataforma de comércio eletrônico abrangente em grande escala que integra comerciantes para vendas de produtos e compras online. serviços de conveniência de disseminação, a avançada plataforma de compras on-line de comércio eletrônico transfronteiriço da Europa.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Atualmente, o grupo de consumidores globais atingiu 80 milhões de pessoas. Agora o principal plano da nossa empresa é expandir o mercado global, por isso há atualmente muito espaço para crescimento, por isso precisamos recrutar um grande número de pessoas que tenham a ideia. de abrir uma loja para se juntar à nossa loja. A plataforma de compras Shopify Outlets convida sinceramente membros comerciantes e indivíduos de todo o mundo a se juntarem a nós, reunirem a sabedoria de outras pessoas e realizarem nossos sonhos comuns. Se você já está começando um negócio ou se perdeu, a plataforma de shopping Shopify Outlets será um palco para você realizar seus sonhos empreendedores. A empresa dá as boas-vindas a pessoas interessadas em e-commerce para ingressar na plataforma de shopping Shopify Outlets e. deseja a você um futuro de sucesso!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Como se tornar um comerciante do Shopify Outlets e iniciar um negócio </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Primeiro, precisamos baixar o software Shopify Outlets por meio da App Store no iPhone ou Google Play. Após a conclusão do download, escolha o método de cadastro que mais lhe convier e cadastre uma conta. Encontre a loja na plataforma e cadastre-se. Clique para preencher seus dados reais e enviar a inscrição. novamente. Agora vamos clicar no Merchant Center para ver como se tornar um comerciante. Como começar a vender seus produtos? Entre no Merchant Center, clique na lista de produtos e depois clique em Adicionar produtos do armazém. pelos fornecedores. Você pode selecionar os produtos que deseja vender e clicar em “Adicionar à sua loja para venda” com um clique e sua loja estará pronta para abrir. Sua loja está pronta para abrir. Você pode decorar sua loja na plataforma. O preço do pedido das mercadorias que você vende é definido pelo fornecedor, e você só pode vendê-las de acordo com o preço das mercadorias que você vende na plataforma. Como lojistas do Shopify Outlets, precisamos cuidar dos clientes que chegam à nossa loja e atendê-los bem, caso contrário poderão surgir reclamações que afetem o score de crédito da loja e multas.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">O processo de ganhar dinheiro para se tornar um comerciante no Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">As funções desempenhadas pelos Shopify Outlets, fornecedores, comerciantes e consumidores, respectivamente</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets fornece operadoras de plataforma</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Os fornecedores fornecem produtos para Shopify Outlets</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Os comerciantes abrem lojas no Shopify Outlets e publicam produtos dos fornecedores na plataforma em suas próprias lojas.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Os consumidores veem seus produtos favoritos nas lojas por meio de anúncios e tráfego e, em seguida, fazem compras.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. O comerciante vê o pedido da mercadoria e depois compra os produtos correspondentes na plataforma</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. O fornecedor envia automaticamente a mercadoria de acordo com o pedido.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Quando o consumidor receber a mercadoria, o Shopify Outlets liquidará o pagamento ao comerciante, e o comerciante terá lucro.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify OutletsWish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">O Shopify Outlets promete a qualidade de qualquer produto adquirido pelos consumidores e garante que todos os comerciantes terão uma experiência muito boa após se instalarem. O Shopify Outlets se tornará outro novo tipo de gigante do comércio eletrônico transfronteiriço no mundo e se tornará o maior exportador Plataforma de comércio eletrônico B2C. Quanto mais cedo você participar como vendedor de e-commerce, mais fácil será ganhar dinheiro. Shopify Outlets dá as boas-vindas a empreendedores de comércio eletrônico estrangeiros. Escolher Shopify Outlets significa escolher riqueza.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Ok, o texto acima é a introdução ao negócio, comece sua jornada empreendedora e aja rapidamente!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
