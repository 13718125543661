<template>
  <section class="head-boxxx elementor-section elementor-top-section elementor-element elementor-element-b89d5f9 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="b89d5f9">
    <div class="menuddd123">
      <div class="logo-box">
        <router-link :to="{ name: 'index' }">
          <img src="@/assets/logo2.png" style="height: 100%" v-if="bg" />

          <img src="@/assets/logo1.png" style="height: 100%" v-else />
        </router-link>
      </div>
    </div>
    <div class="elementor-container elementor-column-gap-default ddd11223Div">
      <div class="elementor-column -elementor-col-20 elementor-top-column elementor-element elementor-element-aa73284" data-id="aa73284" data-element_type="column" style="height: 100%">
        <div class="elementor-widget-wrap elementor-element-populated">
          <div class="elementor-element elementor-element-6bf0296 elementor-widget__width-initial elementor-widget elementor-widget-groovy_menu_plugin" data-id="6bf0296" style="height: 100%">
            <div class="elementor-widget-container" style="height: 100%">
              <header
                class="gm-navbar gm-preset-id-667 gm-navbar--align-left gm-navbar--style-1 gm-top-links-align-right gm-navbar--toolbar-false gm-navbar--shadow-tiny gm-navbar--shadow-dropdown-medium gm-dropdown-hover-style-default gm-dropdown-appearance-animate-from-bottom gm-dropdown-with-scrollbar gm-init-done"
                id="gm-659e29dfc6e66"
                data-version="1.4.3"
                style="height: 100%">
                <div class="gm-wrapper" style="height: 100%">
                  <div class="gm-inner" style="height: 100%">
                    <div class="gm-inner-bg"></div>
                    <div class="gm-container" style="height: 100%">
                      <div class="gm-logo"></div>

                      <div class="gm-main-menu-wrapper d-flex">
                        <nav id="gm-main-menu" data-timeout-close-all="null" data-timeout-check-close="735" style="display: flex; align-items: center">
                          <ul id="menu-top" class="gm-navbar-nav" style="height: 100%; position: relative">
                            <li id="menu-item-530" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown gm-open gm-active" data-close="true" data-timeout-close="733">
                              <a class="gm-anchor gm-dropdown-toggle menu-link">
                                <span class="gm-menu-item__txt-wrapper">
                                  <span class="gm-menu-item__txt">{{ $t('_xcw._sy') }}</span>
                                </span>
                                <span class="gm-caret" aria-label="dropdown"> </span>
                              </a>
                              <div class="gm-dropdown-menu-wrapper">
                                <ul class="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                                  <li id="menu-item-538" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a :href="$Const.HOST" target="_blank" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper">
                                        <span class="gm-menu-item__txt">{{ $t('_xcw._scsy') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <li id="menu-item-538" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#open')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper">
                                        <span class="gm-menu-item__txt">{{ $t('_xcw._wskdlc') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <li id="menu-item-538" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#choose')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper">
                                        <span class="gm-menu-item__txt">{{ $t('_xcw._whxcwm') }}</span></span
                                      >
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li id="menu-item-542" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown gm-open gm-active">
                              <a href="#" class="gm-anchor gm-dropdown-toggle menu-link"
                                ><span class="gm-menu-item__txt-wrapper"
                                  ><span class="gm-menu-item__txt">{{ $t('_xcw._hdzx') }}</span></span
                                ><span class="gm-caret" aria-label="dropdown"></span
                              ></a>
                              <div class="gm-dropdown-menu-wrapper">
                                <ul class="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                                  <li id="menu-item-543" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a :href="$Const.HOST + '/#/halloween'" target="_blank" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper">
                                        <span class="gm-menu-item__txt">{{ $t('_xcw._wsj') }}</span>
                                      </span>
                                    </a>
                                  </li>
                                  <!-- <li id="menu-item-543" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <router-link :to="{ name: 'christmas' }" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"><span class="gm-menu-item__txt">圣诞节</span></span>
                                    </router-link>
                                  </li>
                                  <li id="menu-item-543" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <router-link :to="{ name: 'makeups' }" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"><span class="gm-menu-item__txt">美妆节</span></span>
                                    </router-link>
                                  </li> -->
                                </ul>
                              </div>
                            </li>
                            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown gm-open gm-active">
                              <a class="gm-anchor gm-dropdown-toggle menu-link">
                                <span class="gm-menu-item__txt-wrapper">
                                  <span class="gm-menu-item__txt">{{ $t('_xcw._gsjj') }}</span>
                                </span>
                                <span class="gm-caret" aria-label="dropdown"></span>
                              </a>
                              <div class="gm-dropdown-menu-wrapper">
                                <ul class="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                                  <li id="menu-item-537" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#lhdnazcsr')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._lhcsrs') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <li id="menu-item-537" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#advantages')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._qyss') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <li id="menu-item-537" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#logistics')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._wlys') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <li id="menu-item-537" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#platform')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._ptys') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <li id="menu-item-580" class="menu-item menu-item-type-post_type menu-item-object-page gm-menu-item gm-menu-item--lvl-1">
                                    <!--  v-scroll-to="'#company'" -->
                                    <a @click="onScroll('#company')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._gygs') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <!-- <li id="menu-item-537" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#company')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._tdjs') }}</span></span
                                      >
                                    </a>
                                  </li> -->
                                  <li id="menu-item-537" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#philosophy')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._wmdll') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <li id="menu-item-537" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#dropshopping')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._smjyjdf') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <li id="menu-item-537" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#whyus')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._xcsf') }}</span></span
                                      >
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li id="menu-item-531" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown gm-open gm-active">
                              <a href="#" class="gm-anchor gm-dropdown-toggle menu-link"
                                ><span class="gm-menu-item__txt-wrapper"
                                  ><span class="gm-menu-item__txt">{{ $t('_xcw._sjrz') }}</span></span
                                ><span class="gm-caret" aria-label="dropdown"></span
                              ></a>
                              <div class="gm-dropdown-menu-wrapper">
                                <ul class="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                                  <li id="menu-item-539" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a :href="$Const.HOST + '/#/beseller'" target="_blank" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._sjrz1') }}</span></span
                                      >
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li id="menu-item-529" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown gm-open gm-active" data-close="true" data-timeout-close="210" data-timeout-open="203">
                              <a href="#" class="gm-anchor gm-dropdown-toggle menu-link"
                                ><span class="gm-menu-item__txt-wrapper"
                                  ><span class="gm-menu-item__txt">{{ $t('_xcw._xzapp') }}</span></span
                                ><span class="gm-caret" aria-label="dropdown"></span
                              ></a>
                              <div class="gm-dropdown-menu-wrapper" style="">
                                <ul class="gm-dropdown-menu gm-dropdown-menu--lvl-1 ps" style="position: static; max-height: 849px" data-ps-id="1">
                                  <li id="menu-item-540" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#download')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._xzios') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <li id="menu-item-541" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#download')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._xzaz') }}</span></span
                                      >
                                    </a>
                                  </li>
                                  <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                                    <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
                                  </div>
                                  <div class="ps__rail-y" style="top: 0px; right: 0px">
                                    <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px"></div>
                                  </div>
                                </ul>
                              </div>
                            </li>
                            <li id="menu-item-705" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown gm-open gm-active">
                              <a href="#" class="gm-anchor gm-dropdown-toggle menu-link"
                                ><span class="gm-menu-item__txt-wrapper"
                                  ><span class="gm-menu-item__txt">{{ $t('_xcw._lxwm') }}</span></span
                                ><span class="gm-caret" aria-label="dropdown"></span
                              ></a>
                              <div class="gm-dropdown-menu-wrapper">
                                <ul class="gm-dropdown-menu gm-dropdown-menu--lvl-1">
                                  <!-- <li id="menu-item-707" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a href="https://wa.me/447597732272" class="gm-anchor gm-menu-item__link menu-link"
                                      ><span class="gm-menu-item__txt-wrapper"><span class="gm-menu-item__txt">我是买家</span></span></a
                                    >
                                  </li>
                                  <li id="menu-item-706" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a href="https://wa.me/447597732272" class="gm-anchor gm-menu-item__link menu-link"
                                      ><span class="gm-menu-item__txt-wrapper"><span class="gm-menu-item__txt">我是卖家</span></span></a
                                    >
                                  </li>
                                  <li id="menu-item-708" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a href="https://wa.me/447597732272" class="gm-anchor gm-menu-item__link menu-link"
                                      ><span class="gm-menu-item__txt-wrapper"><span class="gm-menu-item__txt">我想投诉</span></span></a
                                    >
                                  </li> -->
                                  <li id="menu-item-732" class="menu-item menu-item-type-custom menu-item-object-custom gm-menu-item gm-menu-item--lvl-1">
                                    <a @click="onScroll('#contact')" class="gm-anchor gm-menu-item__link menu-link">
                                      <span class="gm-menu-item__txt-wrapper"
                                        ><span class="gm-menu-item__txt">{{ $t('_xcw._lxwm1') }}</span></span
                                      ></a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <!-- 语言 -->
                          </ul>
                        </nav>
                        <div class="gm-actions">
                          <div class="gm-search fullscreen">
                            <i class="gmi gmi-zoom-search"></i>
                            <span class="gm-search__txt">Search</span>
                          </div>
                        </div>
                      </div>

                      <!-- 语言 -->
                      <div style="display: flex; align-items: center; justify-content: center; padding: 0 20px" id="menu-item-705" class="cs23212 menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown">
                        <a href="#" class="gm-anchor gm-dropdown-toggle menu-link">
                          <div class="cs2321">
                            <div :class="'lang-icon langs-' + lang"></div>
                            <a-icon type="down" size="16" />
                            <div class="csbg">
                              <ul>
                                <li class="lang-li" v-for="(vo, i) in list" :key="i" @click="onLange(vo)">
                                  <div :class="'lang-icon langs-' + vo.lang"></div>
                                  <div>{{ vo.text }}</div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div class="gm-menu-btn gm-burger hamburger hamburger--squeeze">
                        <div class="hamburger-box" @click="onClick(true)">
                          <div class="hamburger-inner"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <!-- 右侧栏 -->
              <aside :class="'gm-navigation-drawer gm-navigation-drawer--mobile ' + (isOpen ? '' : 'gm-hidden')">
                <div class="gm-grid-container d-flex flex-column h-100 ps" data-ps-id="0">
                  <div class="gm-menu-btn-close-mobile-drawer gm-hamburger-close" aria-label="close">
                    <div class="hamburger is-active hamburger--squeeze">
                      <div class="hamburger-box 1112" @click="onClick(false)">
                        <div class="hamburger-inner"></div>
                      </div>
                    </div>
                  </div>
                  <div class="gm-mobile-menu-container">
                    <ul id="menu-top-1" class="gm-navbar-nav">
                      <li id="menu-item-mobile-530" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children gm-menu-item gm-menu-item--lvl-0 gm-dropdown gm-open gm-active">
                        <a :href="$Const.WAP_HOST" target="_blank" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._scsy') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a @click="onScroll('#open')" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._wskdlc') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a @click="onScroll('#choose')" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._whxcwm') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a :href="$Const.WAP_HOST + '/#/pages/halloween/halloween'" target="_blank" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._wsj') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a @click="onScroll('#advantages')" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._qyss') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a @click="onScroll('#logistics')" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._wlys') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a @click="onScroll('#platform')" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._ptys') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a @click="onScroll('#company')" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._gygs') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a @click="onScroll('#philosophy')" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._wmdll') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a @click="onScroll('#whyus')" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._xcsf') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a :href="$Const.WAP_HOST + '/#/pages/beseller/beseller'" target="_blank" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._sjrz1') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._xzapp') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                        <a @click="onScroll('#contact')" class="gm-anchor gm-dropdown-toggle menu-link">
                          <span class="gm-menu-item__txt-wrapper">
                            <span class="gm-menu-item__txt">{{ $t('_xcw._lxwm') }}</span>
                          </span>
                          <span class="gm-caret" aria-label="dropdown"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="flex-grow-1"></div>
                  <div class="gm-mobile-action-area-wrapper d-flex justify-content-center align-items-center text-center mb-4 mt-5">
                    <div class="gm-search fullscreen">
                      <i class="gm-icon gmi gmi-zoom-search"></i>
                      <span class="gm-search__txt">Search</span>
                    </div>
                  </div>
                  <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                    <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
                  </div>
                  <div class="ps__rail-y" style="top: 0px; right: 0px">
                    <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px"></div>
                  </div>
                </div>
                <div class="gm-mobile-postwrap"></div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 返回顶部 -->
    <div>
      <div class="ttop" @click="onTop">Top</div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeadBox',
  props: {
    bg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      lang: 'en-us',
      list: this.$Const.LANGUAGE
    }
  },
  created() {
    this.lang = this.$getLangauge()
  },
  methods: {
    onTop() {
      this.$scrollTo('#top', 100)
    },
    onScroll(name) {
      let thta = this
      if (this.$route.name !== 'index') {
        this.$router.push({ name: 'index' }).then(() => {
          setTimeout(function () {
            thta.$scrollTo(name, 1000)
          }, 200)
        })
      } else {
        this.$scrollTo(name, 100)
      }
    },
    onLange(vo) {
      let language = {
        text: vo.text,
        lang: vo.lang
      }
      //缓存
      this.lang = vo.lang
      this.$setLangauge(language.lang)
      // location.reload();
    },
    onClick(val) {
      this.isOpen = val
      console.log(this.isOpen)
    }
  }
}
</script>

<style scoped src="@/assets/css/frontend.css?ver=1.4.3"></style>
<style scoped src="@/assets/css/main.min.css?ver=4.1.3"></style>
<style scoped src="@/assets/css/post-588.css?ver=1681713778"></style>
<style scoped src="@/assets/css/post-9.css?ver=1686130466"></style>
<!-- <style scoped src="@/assets/css/frontend-lite.min.css?ver=3.12.1"></style> -->
<style scoped src="@/assets/css/preset_667.css?ver=23a8f291edff043609365a2d40db131e"></style>
<style scoped src="@/assets/css/solid.min.css?ver=5.15.3"></style>
<style scoped src="@/assets/css/1.css"></style>
<style scoped src="@/assets/css/2.css"></style>
<style scoped src="@/assets/lang/lang.css"></style>
<style scoped>
.ttop {
  cursor: pointer;
  position: fixed;
  bottom: 10%;
  right: 10%;
  border: 1px solid #f3eeee;
  padding: 12px;
  font-size: 14px;
  border-radius: 5px;
  z-index: 100;
  background: #eee;
  font-weight: 700;
}

.ttop:hover {
  background: #fff;
}
</style>
<style scoped>
.logo-box {
  display: flex;
  width: auto;
  height: 60px;
}

@media only screen and (max-width: 767px) {
  .logo-box {
    height: auto;
  }
}

.head-boxxx {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 11;
  background: linear-gradient(#191919 85%, #191919 75%, transparent);
}
</style>
