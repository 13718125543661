export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Làm việc nhóm, hiệu quả cao và thực dụng, đổi mới tích cực, thoải mái, hài hòa</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Giá trị cốt lõi của Shopify Outlets cũng phản ánh tinh thần kinh doanh chủ động của chúng tôi. Chúng tôi là một nền tảng khởi nghiệp hoàn toàn mới, không yêu cầu số vốn lớn, trình độ học vấn cao hoặc nền tảng điện thoại di động, vì vậy bạn có thể bắt đầu kinh doanh và trở thành ông chủ tại nhà.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Nền tảng Shopify Outlets mang đến cơ hội kinh doanh cho mọi người</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Đến cuối năm 2022, khối lượng giao dịch trong một ngày cao nhất sẽ đạt 3,24 tỷ USD, tạo ra tổng cộng 2,365 triệu việc làm trực tiếp và cơ hội việc làm đầy đủ.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Nền tảng Shopify Outlets đã tạo nên một sân khấu hoành tráng cho tất cả những ai muốn bắt đầu sử dụng thương mại điện tử, tập hợp tất cả mọi người từ thực phẩm, quần áo, nhà ở và mọi tầng lớp xã hội để hiện thực hóa ước mơ khởi nghiệp và làm giàu của chúng tôi. Bill Gates, người giàu nhất thế giới, cho rằng thế kỷ 21 sẽ có thương mại điện tử hoặc không có hoạt động kinh doanh nào cả. Li Ka-shing, doanh nhân giàu có Hong Kong, cho rằng thương mại điện tử là một ngành mới, mọi cơ hội kinh doanh nảy sinh sẽ tạo ra một nhóm người giàu. Đồng thời, các chuyên gia của Heke chỉ ra rằng từ góc độ mô hình kinh doanh, mua sắm trực tuyến toàn cầu cuối cùng sẽ được thống nhất và kết hợp, cuối cùng sẽ thống nhất và tích hợp các mô hình phát triển trực tuyến và ngoại tuyến.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Nền tảng mua sắm Shopify Outlets tích hợp mô hình B2C, mô hình C2C và mô hình thương mại điện tử trực tuyến và ngoại tuyến O2O. Đây là nền tảng thương mại điện tử toàn diện quy mô lớn, tích hợp người bán để bán sản phẩm và mua sắm trực tuyến. Nó tích hợp thông tin quảng cáo trực tuyến và ngoại tuyến. nền tảng mua sắm trực tuyến thương mại điện tử xuyên biên giới tiên tiến của Châu Âu.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Hiện tại, nhóm người tiêu dùng toàn cầu đã đạt 80 triệu người. Hiện tại, kế hoạch chính của công ty chúng tôi là mở rộng thị trường toàn cầu nên hiện tại còn rất nhiều dư địa để phát triển nên chúng tôi cần tuyển một số lượng lớn người có ý tưởng. về việc mở một cửa hàng để tham gia cửa hàng của chúng tôi. Nền tảng mua sắm Shopify Outlets chân thành mời các thành viên thương gia và cá nhân từ khắp nơi trên thế giới tham gia cùng chúng tôi, tổng hợp trí tuệ của người khác và hiện thực hóa ước mơ chung của chúng ta. Cho dù bạn đang bắt đầu kinh doanh hay đang lạc lối, nền tảng trung tâm mua sắm Shopify Outlets sẽ cung cấp cho bạn một sân khấu để hiện thực hóa ước mơ kinh doanh của mình. Công ty nồng nhiệt chào đón những người quan tâm đến thương mại điện tử tham gia nền tảng trung tâm mua sắm Shopify Outlets và. chúc bạn có một sự nghiệp thành công trong tương lai!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Cách trở thành người bán Shopify Outlets và bắt đầu kinh doanh </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Đầu tiên chúng ta cần tải xuống phần mềm Shopify Outlets thông qua App Store trên iPhone hoặc Google Play. Sau khi tải xuống hoàn tất, hãy chọn phương thức đăng ký phù hợp với bạn và đăng ký tài khoản. Tìm cửa hàng trong nền tảng và đăng ký. Nhấp để điền thông tin thực của bạn và gửi đơn đăng ký. Sau khi nền tảng được xem xét và phê duyệt, bạn có thể đăng nhập. vào lại. Bây giờ, hãy nhấp vào Merchant Center để xem cách trở thành người bán. Làm thế nào để bắt đầu bán sản phẩm của bạn? Vào Merchant Center, nhấp vào danh sách sản phẩm, sau đó nhấp vào Thêm sản phẩm từ Kho. bởi các nhà cung cấp. Bạn có thể chọn sản phẩm bạn muốn bán, sau đó nhấp vào &quot;Thêm vào cửa hàng để bán&quot; chỉ bằng một cú nhấp chuột và cửa hàng của bạn đã sẵn sàng mở. Cửa hàng của bạn đã sẵn sàng mở. Bạn có thể trang trí cửa hàng của mình trên nền tảng. Giá đặt hàng của hàng hóa bạn bán do nhà cung cấp đặt ra và bạn chỉ có thể bán chúng theo giá của hàng hóa bạn bán trên nền tảng. Với tư cách là thương nhân của Shopify Outlets, chúng tôi cần chăm sóc những khách hàng đến cửa hàng của mình và phục vụ họ chu đáo, nếu không có thể có khiếu nại ảnh hưởng đến điểm tín dụng và hình phạt của cửa hàng.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Quá trình kiếm tiền khi trở thành thương nhân trong Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Vai trò của các Cửa hàng Shopify, nhà cung cấp, thương nhân và người tiêu dùng tương ứng</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets cung cấp nền tảng vận chuyển</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Nhà cung cấp cung cấp sản phẩm cho Shopify Outlets</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Thương nhân mở cửa hàng trong Shopify Outlets và đăng sản phẩm từ nhà cung cấp trên nền tảng lên cửa hàng của chính họ.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Người tiêu dùng nhìn thấy sản phẩm yêu thích của họ trong các cửa hàng thương mại thông qua quảng cáo và lưu lượng truy cập, sau đó mua hàng.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. Người bán xem đơn hàng và sau đó mua sản phẩm tương ứng trên nền tảng</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Nhà cung cấp tự động vận chuyển hàng hóa theo đơn đặt hàng.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Khi người tiêu dùng nhận được hàng, Shopify Outlets sẽ thanh toán khoản thanh toán cho người bán và người bán sẽ kiếm được lợi nhuận.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Cửa Hàng ShopifyMong Muốn</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets hứa hẹn về chất lượng của bất kỳ sản phẩm nào mà người tiêu dùng mua và đảm bảo rằng tất cả người bán sẽ có trải nghiệm rất tốt sau khi ổn định cuộc sống. Shopify Outlets sẽ trở thành một loại hình khổng lồ thương mại điện tử xuyên biên giới mới trên thế giới và phát triển thành nơi xuất khẩu lớn nhất Nền tảng thương mại điện tử B2C. Bạn tham gia với tư cách là người bán thương mại điện tử càng sớm thì càng dễ kiếm tiền. Shopify Outlets chào đón các doanh nhân thương mại điện tử nước ngoài tham gia. Chọn Shopify Outlets có nghĩa là chọn sự giàu có.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Được rồi, trên đây là phần giới thiệu về doanh nghiệp, hãy bắt đầu hành trình khởi nghiệp của bạn và hành động nhanh chóng!</span>
</p>`
