<template>
  <section
    class="mb-10 md:mb-0 sm:pb-2 pt-global-header md:pb-8 relative z-10 before:absolute before:top-0 before:left-0 before:w-full md:before:z-[1] before:z-[-1] before:h-[160%] before:bg-homepage-hero-before before:bg-position-[0_0] before:bg-[length:auto,32px_32px] after:absolute after:top-0 after:left-0 after:z-[0] after:h-[135%] after:blur-[10px] after:bg-homepage-hero-after after:w-full [&amp;_form>div]:w-full"
    data-section-name="hero"
    data-component-name="hero"
    data-viewable-component="true"
    data-component-extra-variant="non-dna">
    <!-- <div class="h-global-header absolute z-20 top-0 left-0 w-full bg-[linear-gradient(rgba(255,255,255,0.933)_60%,rgba(255,255,255,0.8)_75%,transparent_100%)]"></div> -->
    <div class="container relative z-30 grid md:grid-cols-12 justify-between">
      <div class="w-full mt-10 mb-6 md:mb-32 md:col-span-6 lg:col-span-5">
        <!-- <a class="mb-9 hidden md:inline-block rounded-full border border-[#CAD4D7] px-7 py-[0.65rem] hover:border-[#A8B1B4]" type="button" href="#/video/xomzwb1moi">
          <span class="mr-2 inline-block border-y-[6px] border-l-[6px] border-r-0 border-solid border-y-transparent border-l-black"></span>
          Shopifyに関する2分動画を視聴する
        </a> -->
        <div class="text-left text-section-light-text mb-9" data-mode="light" data-component-name="heading-group">
          <h1 class="richtext text-t1">{{ $t('_xcw._qqswhz') }}</h1>
          <p class="richtext text-body-lg pt-md">{{ $t('_xcw._syxsqd') }}</p>
        </div>
        <div
          class="md:p-px md:rounded-full md:bg-homepage-hero-form-wrapper [&amp;_form>div:first-of-type]:border [&amp;_form>div:first-of-type]:border-transparent [&amp;_form>div:first-of-type]:bg-white [&amp;_form>div:first-of-type:has(input:focus)]:border-[#555] [@media(max-width:899px)]:[&amp;_form>div:first-child]:border [@media(max-width:899px)]:[&amp;_form>div:first-child]:border-[#dadee8]">
          <div class="flex justify-center">
            <h2 class="sr-only">{{ $t('_xcw._mfrz') }}</h2>
            <div class="form flex w-full flex-col justify-center items-center">
              <div class="box-border flex h-16 w-full items-center justify-between rounded-full border-2 pl-6 pr-1 sm:w-[498px] border-black bg-transparent">
                <input style="border: 0" v-model="email" type="email" spellcheck="false" autocomplete="off" class="text-shade-70 h-14 w-full bg-transparent sm:w-72 outline-none" :placeholder="$t('_xcw._srdzyj')" />
                <div class="hidden sm:block">
                  <button
                    @click="onSubmit"
                    class="font-bold inline-block overflow-hidden max-w-full transition-all focus-visible:outline-4 focus-visible:outline-state-focus focus-visible:outline-offset-[-2px] border-solid border-2 rounded-[30px] focus-visible:outline-none tracking-ff-tight text-body-base leading-none px-[1.75rem] py-[1.1875rem] border-[transparent] text-white bg-shade-100 hover:bg-shade-70 active:bg-shade-50 disabled:bg-shade-20 disabled:text-shade-30 whitespace-nowrap"
                    data-component-name="start-free-trial"
                    style="padding-top: 16px; padding-bottom: 16px">
                    {{ $t('_xcw._mfrz') }}
                  </button>
                </div>
              </div>
              <div class="block w-full sm:hidden">
                <button
                  @click="onSubmit"
                  class="font-bold inline-block overflow-hidden max-w-full transition-all focus-visible:outline-4 focus-visible:outline-state-focus focus-visible:outline-offset-[-2px] border-solid border-2 rounded-[30px] focus-visible:outline-none tracking-ff-tight text-body-base leading-none px-[1.75rem] py-[1.1875rem] border-[transparent] text-white bg-shade-100 hover:bg-shade-70 active:bg-shade-50 disabled:bg-shade-20 disabled:text-shade-30 whitespace-nowrap"
                  data-component-name="start-free-trial"
                  style="width: 100%; margin-top: 1rem">
                  {{ $t('_xcw._mfrz') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="h-md w-full"></div>
        <p class="text-xs richtext">{{ $t('_xcw._rzbsty') }}</p>
      </div>

      <scroll-top></scroll-top>
    </div>
  </section>
</template>

<script>
import ScrollTop from './ScrollTop.vue'
// import { Navigation, Pagination, Autoplay } from 'swiper'
export default {
  components: { ScrollTop },
  data() {
    return {
      // modules: [Navigation, Pagination, Autoplay],
      total: 0,
      current: 0,
      //轮播图
      banner: [],

      //输入的邮箱
      email: ''
    }
  },
  created() {},
  methods: {
    onSubmit() {
      if (this.email.length > 0) {
        let url = this.$Const.HOST + `/#/beseller?email=${this.email}`
        console.log(url)
        window.open(url, '_blank')
      }
    },
    onUrl(url) {
      window.open(url, '_blank')
    },
    onRenderCustom(swiper, current, total) {
      this.total = total
      this.current = current
    }
  }
}
</script>

<style scoped>
@import url('@/assets/css5/home-B9yk3cbq.css');
</style>
