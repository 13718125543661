export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Trabajo en equipo, alta eficiencia y pragmatismo, innovación positiva, relajada y armoniosa.</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Los valores fundamentales de Shopify Outlets también son un reflejo de nuestro espíritu emprendedor proactivo. Somos una plataforma empresarial completamente nueva que no requiere grandes fondos, educación superior ni conocimientos de telefonía móvil, para que pueda iniciar un negocio y ser el jefe en casa.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">La plataforma Shopify Outlets acerca oportunidades de negocio a todos</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">A finales de 2022, el volumen máximo de transacciones en un solo día alcanzará los 3.240 millones de dólares, creando un total de 2.365 millones de empleos directos y oportunidades de pleno empleo.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">La plataforma Shopify Outlets ha creado un gran escenario para todos los que quieran comenzar a utilizar el comercio electrónico, reuniendo a todos, desde alimentos, ropa, vivienda y todos los ámbitos de la vida, para hacer realidad nuestro sueño de iniciar un negocio y hacernos ricos. Bill Gates, el hombre más rico del mundo, dijo que el siglo XXI será comercio electrónico o ningún negocio. Li Ka-shing, un rico hombre de negocios de Hong Kong, dijo que el comercio electrónico es una industria nueva y que cada oportunidad de negocio que surja creará un grupo de gente rica. Al mismo tiempo, los expertos de Heke señalaron que desde la perspectiva del modelo de negocio, las compras globales en línea eventualmente se unificarán y combinarán, y eventualmente unificarán e integrarán los modelos de desarrollo en línea y fuera de línea.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">La plataforma de compras Shopify Outlets integra el modelo B2C, el modelo C2C y los modelos de comercio electrónico en línea y fuera de línea O2O. Es una plataforma de comercio electrónico integral a gran escala que integra a los comerciantes para la venta de productos y las compras en línea. Servicios de conveniencia de difusión. Plataforma de compras en línea de comercio electrónico transfronterizo avanzado de Europa.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">En la actualidad, el grupo de consumidores global ha llegado a 80 millones de personas. Ahora el plan principal de nuestra empresa es expandir el mercado global, por lo que actualmente hay mucho espacio para crecer, por lo que necesitamos contratar una gran cantidad de personas que tengan la idea. de abrir una tienda para unirse a nuestra tienda. La plataforma de compras Shopify Outlets invita sinceramente a miembros comerciantes e individuos de todo el mundo a unirse a nosotros, reunir la sabiduría de otros y hacer realidad nuestros sueños comunes. Ya sea que ya esté iniciando un negocio o haya perdido el rumbo, la plataforma del centro comercial Shopify Outlets le brindará un escenario para hacer realidad sus sueños empresariales. La compañía da una calurosa bienvenida a las personas interesadas en el comercio electrónico para que se unan a la plataforma del centro comercial Shopify Outlets y. ¡Le desea un futuro exitoso!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Cómo convertirse en comerciante de Shopify Outlets y comenzar un negocio </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Primero debemos descargar el software Shopify Outlets a través de la App Store en iPhone o Google Play. Una vez completada la descarga, elija el método de registro que más le convenga y registre una cuenta. Busque la tienda en la plataforma y regístrese para completar su información real y enviar la solicitud. Una vez revisada y aprobada la plataforma, podrá iniciar sesión. Ingrese nuevamente. Ahora hagamos clic en Merchant Center para ver cómo convertirse en comerciante. ¿Cómo comenzar a vender sus productos? Ingrese a Merchant Center, haga clic en la lista de productos y luego haga clic en Agregar productos del almacén. por proveedores. Puede seleccionar los productos que desea vender, luego hacer clic en &quot;Agregar a su tienda para la venta&quot; con un solo clic y su tienda estará lista para abrir. Su tienda está lista para abrir. Puede decorar su tienda en la plataforma. El precio del pedido de los productos que vende lo establece el proveedor y solo puede venderlos de acuerdo con el precio de los productos que vende en la plataforma. Como comerciante de Shopify Outlets, debemos cuidar a los clientes que vienen a nuestra tienda y brindarles un buen servicio; de lo contrario, puede haber quejas que afecten el puntaje crediticio de la tienda y sanciones.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">El proceso de ganar dinero al convertirse en comerciante en Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Los roles desempeñados por Shopify Outlets, proveedores, comerciantes y consumidores respectivamente.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets proporciona operadores de plataforma</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Los proveedores proporcionan productos a Shopify Outlets</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Los comerciantes abren tiendas en Shopify Outlets y publican productos de proveedores en la plataforma en sus propias tiendas.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Los consumidores ven sus productos favoritos en las tiendas comerciales a través de anuncios y tráfico, y luego realizan compras.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. El comerciante ve el pedido de mercancías y luego compra los productos correspondientes en la plataforma.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. El proveedor envía automáticamente la mercancía según el pedido.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Cuando el consumidor reciba los productos, Shopify Outlets liquidará el pago al comerciante y el comerciante obtendrá una ganancia.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify OutletsDeseo</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets promete la calidad de cualquier producto comprado por los consumidores y garantiza que todos los comerciantes tendrán una muy buena experiencia después de instalarse. Shopify Outlets se convertirá en otro nuevo tipo de gigante del comercio electrónico transfronterizo en el mundo y se convertirá en el mayor exportador. Plataforma de comercio electrónico B2C. Cuanto antes participe como vendedor de comercio electrónico, más fácil será ganar dinero. Shopify Outlets da la bienvenida a los empresarios de comercio electrónico extranjeros para que se unan. Elegir Shopify Outlets significa elegir riqueza.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Bien, lo anterior es la introducción del negocio, ¡comience su viaje empresarial y actúe rápidamente!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
