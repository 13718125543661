export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Teamarbeit, hohe Effizienz und Pragmatismus, positive Innovation, entspannt und harmonisch</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Die Grundwerte von Shopify Outlets spiegeln auch unseren proaktiven Unternehmergeist wider. Wir sind eine brandneue Unternehmerplattform, die keine großen finanziellen Mittel, keine hohe Ausbildung oder keinen Mobilfunkhintergrund erfordert, sodass Sie ein Unternehmen gründen und zu Hause der Chef sein können.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Die Shopify Outlets-Plattform bietet Geschäftsmöglichkeiten für jedermann</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Bis Ende 2022 wird das maximale eintägige Transaktionsvolumen 3,24 Milliarden US-Dollar erreichen, wodurch insgesamt 2,365 Millionen direkte Arbeitsplätze und Vollbeschäftigungsmöglichkeiten entstehen.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Die Shopify Outlets-Plattform hat eine große Bühne für alle geschaffen, die mit der Nutzung des E-Commerce beginnen möchten, und bringt alle aus den Bereichen Lebensmittel, Kleidung, Wohnen und alle Lebensbereiche zusammen, um unseren Traum, ein Unternehmen zu gründen und reich zu werden, zu verwirklichen. Bill Gates, der reichste Mann der Welt, sagte, dass das 21. Jahrhundert entweder E-Commerce oder überhaupt kein Geschäft sein wird. Li Ka-shing, ein wohlhabender Geschäftsmann aus Hongkong, sagte, dass E-Commerce eine neue Branche sei und jede sich bietende Geschäftsmöglichkeit eine Gruppe reicher Leute hervorbringen werde. Gleichzeitig wiesen Heke-Experten darauf hin, dass das globale Online-Shopping aus Sicht des Geschäftsmodells letztendlich vereinheitlicht und kombiniert wird und schließlich Online- und Offline-Entwicklungsmodelle vereinheitlicht und integriert werden.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Die Einkaufsplattform Shopify Outlets integriert B2C-Modelle, C2C-Modelle und O2O-Online- und Offline-E-Commerce-Modelle. Es handelt sich um eine umfassende E-Commerce-Plattform, die Händler für Produktverkäufe und Online-Einkäufe integriert Verbreitung von Convenience-Diensten, Europas fortschrittlicher grenzüberschreitender E-Commerce-Online-Shopping-Plattform.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Derzeit hat die globale Verbrauchergruppe 80 Millionen Menschen erreicht. Der Hauptplan unseres Unternehmens besteht nun darin, den globalen Markt zu erweitern. Daher gibt es derzeit viel Raum für Wachstum. Daher müssen wir eine große Anzahl von Menschen rekrutieren, die die Idee haben der Eröffnung eines Ladens, um unserem Laden beizutreten. Die Einkaufsplattform Shopify Outlets lädt Händlermitglieder und Einzelpersonen aus der ganzen Welt herzlich ein, sich uns anzuschließen, das Wissen anderer zu bündeln und unsere gemeinsamen Träume zu verwirklichen. Unabhängig davon, ob Sie bereits ein Unternehmen gründen oder sich noch nicht orientieren, bietet Ihnen die Shopify Outlets-Einkaufszentrumsplattform die Möglichkeit, Ihre unternehmerischen Träume zu verwirklichen. Das Unternehmen heißt Menschen, die sich für E-Commerce interessieren, herzlich willkommen, sich der Shopify Outlets-Einkaufszentrumsplattform anzuschließen wünscht Ihnen eine erfolgreiche Zukunft!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">So werden Sie Shopify Outlets-Händler und gründen ein Unternehmen. </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Zuerst müssen wir die Shopify Outlets-Software über den App Store auf dem iPhone oder Google Play herunterladen. Nachdem der Download abgeschlossen ist, wählen Sie die für Sie geeignete Registrierungsmethode und registrieren Sie den Shop auf der Plattform. Geben Sie Ihre tatsächlichen Daten ein und senden Sie den Antrag ab Klicken Sie nun erneut auf das Merchant Center, um zu erfahren, wie Sie mit dem Verkauf Ihrer Produkte beginnen. Klicken Sie auf die Produktliste und dann auf „Produkte aus dem Lager hinzufügen“. durch Lieferanten. Sie können die Produkte auswählen, die Sie verkaufen möchten, dann mit einem Klick auf „Zu Ihrem Shop zum Verkauf hinzufügen“ klicken und Ihr Shop ist bereit zur Eröffnung. Ihr Geschäft ist bereit zur Eröffnung. Sie können Ihr Geschäft auf der Plattform dekorieren. Der Bestellpreis der von Ihnen verkauften Waren wird vom Lieferanten festgelegt und Sie können sie nur entsprechend dem Preis der Waren verkaufen, die Sie auf der Plattform verkaufen. Als Shopify Outlets-Händler müssen wir uns um die Kunden kümmern, die in unser Geschäft kommen, und sie gut bedienen, andernfalls kann es zu Beschwerden kommen, die sich auf die Kreditwürdigkeit des Geschäfts auswirken, und zu Strafen.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Der Prozess des Geldverdienens als Händler in Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Die Rollen, die Shopify Outlets, Lieferanten, Händler und Verbraucher spielen</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets bietet Plattformbetreiber</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Lieferanten liefern Produkte an Shopify Outlets</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Händler eröffnen Geschäfte in Shopify Outlets und veröffentlichen Produkte von Lieferanten auf der Plattform in ihren eigenen Geschäften.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Verbraucher sehen ihre Lieblingsprodukte in Händlergeschäften durch Werbung und Traffic und tätigen dann einen Kauf.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. Der Händler sieht die Warenbestellung und kauft dann die entsprechenden Produkte auf der Plattform</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Der Lieferant versendet die Ware automatisch entsprechend der Bestellung.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Wenn der Verbraucher die Ware erhält, begleicht Shopify Outlets die Zahlung an den Händler und der Händler erzielt einen Gewinn.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify OutletsWish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets verspricht die Qualität aller von Verbrauchern gekauften Produkte und stellt sicher, dass alle Händler nach der Eingewöhnung eine sehr gute Erfahrung machen werden. Shopify Outlets wird zu einem weiteren neuen Typ von grenzüberschreitendem E-Commerce-Riesen auf der Welt werden und sich zum größten Exportunternehmen entwickeln B2C-E-Commerce-Plattform. Je früher Sie als E-Commerce-Verkäufer teilnehmen, desto einfacher ist es, Geld zu verdienen. Shopify Outlets heißt E-Commerce-Unternehmer aus Übersee willkommen. Wer sich für Shopify Outlets entscheidet, entscheidet sich für Wohlstand.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Okay, das Obige ist die Geschäftseinführung, beginnen Sie Ihre unternehmerische Reise und handeln Sie schnell!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
