<template>
  <div id="top" class="elementor elementor-9">
    <head-box class="heade"></head-box>

    <BannerVue></BannerVue>

    <section class="elementor-section elementor-top-section elementor-element elementor-element-430fbd8c elementor-section-boxed elementor-section-height-default elementor-section-height-default">
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-30172170" data-id="30172170" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-32d42a08 elementor-widget elementor-widget-heading" data-id="32d42a08" style="margin-bottom: 0">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default" style="justify-content: center; display: flex">
                  <img src="@/assets/logo3.png" alt="" style="width: 50%" />
                </h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-338f4f8d elementor-widget elementor-widget-heading" data-id="338f4f8d" data-element_type="widget" data-widget_type="heading.default">
              <div class="elementor-widget-container" style="padding: 0">
                <h5 class="elementor-heading-title elementor-size-default">
                  <div style="display: flex; align-items: center">
                    <div class="kxhask">{{ $t('_xcw._bt1') }}</div>
                    <div class="kxhask">{{ $t('_xcw._bt2') }}</div>
                    <div class="kxhask">{{ $t('_xcw._bt3') }}</div>
                    <div class="kxhask">{{ $t('_xcw._bt4') }}</div>
                  </div>
                  <div style="line-height: 34px; text-align: left">{{ $t('_xcw._dkjt0') }}{{ $t('_xcw._dkjt1') }}{{ $t('_xcw._dkjt2') }}</div>
                </h5>
              </div>
            </div>
            <div class="elementor-element elementor-element-7388b39e elementor-align-center elementor-widget elementor-widget-button" data-id="7388b39e" data-element_type="widget" data-widget_type="button.default">
              <div class="elementor-widget-container">
                <div class="elementor-button-wrapper">
                  <router-link to="/docs" class="elementor-button-link elementor-button elementor-size-sm">
                    <span class="elementor-button-content-wrapper">
                      <span class="elementor-button-text" style="">{{ $t('_xcw._ydgd') }}</span>
                    </span>
                  </router-link>
                </div>
              </div>
            </div>
            <!-- <div class="elementor-element elementor-element-b4ca32a elementor-widget elementor-widget-spacer" data-id="b4ca32a" data-element_type="widget" data-widget_type="spacer.default">
              <div class="elementor-widget-container">
                <div class="elementor-spacer">
                  <div class="elementor-spacer-inner"></div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>

    <!-- 嫁接 -->
    <section style="padding-top: 120px" id="open">
      <div class="b1d">
        <div class="b1d a100012">
          <div class="atitle100">{{ $t('_xcw._wskd') }}</div>

          <div class="b1d sdf2121 minpb">
            <div class="adb20201">
              <div class="absdf">
                <img src="../assets/img/1.png" />
              </div>
              <div class="f1120">{{ $t('_xcw._lxkf') }}</div>
            </div>
            <div class="adb20201">
              <div class="absdf">
                <img src="../assets/img/2.png" />
              </div>
              <div class="f1120">{{ $t('_xcw._sqkd') }}</div>
            </div>
            <div class="adb20201">
              <div class="absdf">
                <img src="../assets/img/3.png" />
              </div>
              <div class="f1120">{{ $t('_xcw._spsj') }}</div>
            </div>
            <div class="adb20201">
              <div class="absdf">
                <img src="../assets/img/4.png" />
              </div>
              <div class="f1120">{{ $t('_xcw._hdddls') }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section style="padding-top: 120px" id="choose">
      <div class="b1d">
        <div class="b1d a100012">
          <div class="atitle100">{{ $t('_xcw._xzdly') }}</div>
          <div>{{ $t('_xcw._zlysx') }}</div>
          <div class="b1d sdf21223123">
            <div class="ad1dsdf bdda2">
              <div class="f1120">{{ $t('_xcw._0ykd') }}</div>
              <div class="few564">{{ $t('_xcw._ptbsfy') }}</div>
            </div>
            <div class="ad1dsdf bdda2">
              <div class="f1120">{{ $t('_xcw._spgy') }}</div>
              <div class="few564">{{ $t('_xcw._jxtx') }}</div>
            </div>
            <div class="ad1dsdf bdda2">
              <div class="f1120">{{ $t('_xcw._zjxs') }}</div>
              <div class="few564">{{ $t('_xcw._qqfw') }}</div>
            </div>
            <div class="ad1dsdf bdda2">
              <div class="f1120">{{ $t('_xcw._llzc') }}</div>
              <div class="few564">{{ $t('_xcw._qsxd') }}</div>
            </div>
            <div class="ad1dsdf bdda2">
              <div class="f1120">{{ $t('_xcw._kfzc') }}</div>
              <div class="few564">{{ $t('_xcw._txzcn') }}</div>
            </div>
            <div class="ad1dsdf bdda2">
              <div class="f1120">{{ $t('_xcw._shfw') }}</div>
              <div class="few564">{{ $t('_xcw._dcbth') }}</div>
            </div>
            <div class="ad1dsdf bdda2">
              <div class="f1120">{{ $t('_xcw._3tkh') }}</div>
              <div class="few564">{{ $t('_xcw._bznsj') }}</div>
            </div>
            <div class="ad1dsdf bdda2">
              <div class="f1120">{{ $t('_xcw._7tdg') }}</div>
              <div class="few564">{{ $t('_xcw._sjltl') }}</div>
            </div>
            <div class="ad1dsdf bdda2">
              <div class="f1120">{{ $t('_xcw._ptfyd') }}</div>
              <div class="few564">{{ $t('_xcw._ddlsg') }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="lhdnazcsr" style="padding-top: 120px">
      <div class="b1d">
        <div class="a12342">
          <div class="adf21 adf1233">
            <div class="agds">
              <img src="../assets/img/5.png" />
            </div>
          </div>
          <div class="adf21">
            <div class="agds23">
              <div class="asdf312">{{ $t('_xcw._lhcsr') }}</div>
              <div>
                {{ $t('_xcw._csrjs') }}
              </div>
              <div class="asdf223">
                <a class="asdfbtn" href="https://en.m.wikipedia.org/wiki/John_Furner#" target="_blank">{{ $t('_xcw._ydgd') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section style="padding-top: 120px" id="advantages">
      <div class="b1d">
        <div class="asva2" style="flex-direction: column; text-align: left">
          <div class="asdf32">{{ $t('_xcw._qyss') }}</div>
          <div>
            <!-- <div style="font-weight: 600">Team Advantage</div> -->
            <div>
              {{ $t('_xcw._qyys1') }}
            </div>
            <div class="b1d sdf2bd2">
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/6.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Contact Customer Service</div> -->
              </div>
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/7.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Apply to open a store</div> -->
              </div>
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/8.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Products on shelves</div> -->
              </div>
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/9.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Get order profit</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section style="padding-top: 120px" id="logistics">
      <div class="b1d">
        <div class="asva2" style="flex-direction: column; text-align: left">
          <div class="asdf32">{{ $t('_xcw._wlys') }}</div>
          <div>
            <!-- <div style="font-weight: 600">ShopifyOutlets</div> -->
            <div>
              {{ $t('_xcw._wlsysm') }}
            </div>
            <div class="b1d sdf2bd2">
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/10.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Contact Customer Service</div> -->
              </div>
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/11.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Apply to open a store</div> -->
              </div>
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/12.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Products on shelves</div> -->
              </div>
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/13.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Get order profit</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section style="padding-top: 120px" id="platform">
      <div class="b1d">
        <div class="asva2" style="flex-direction: column; text-align: left">
          <div class="asdf32">{{ $t('_xcw._ptys') }}</div>
          <div>
            <!-- <div style="font-weight: 600">ShopifyOutlets</div> -->
            <div>
              {{ $t('_xcw._ptysd1') }}
            </div>
            <div class="b1d sdf2bd2">
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/14.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Contact Customer Service</div> -->
              </div>
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/15.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Apply to open a store</div> -->
              </div>
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/16.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Products on shelves</div> -->
              </div>
              <div class="adb142201">
                <div class="absdf">
                  <img src="../assets/img/17.png" style="width: 100%" />
                </div>
                <!-- <div class="f1120">Get order profit</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 嫁接 -->
    <section
      id="company"
      style="padding-top: 120px"
      class="elementor-section elementor-top-section elementor-element elementor-element-5864afee elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="5864afee">
      <div class="elementor-container elementor-column-gap-no">
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-78cf731f" data-id="78cf731f" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-24f09300 elementor-widget elementor-widget-heading" data-id="24f09300" data-element_type="widget" data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h4 class="elementor-heading-title elementor-size-default">{{ $t('_xcw._gygs') }}</h4>
              </div>
            </div>
            <div class="elementor-element elementor-element-904d1e2 elementor-widget elementor-widget-text-editor" data-id="904d1e2" data-element_type="widget" data-widget_type="text-editor.default">
              <div class="elementor-widget-container">
                <p>
                  <span style="vertical-align: inherit">
                    <span style="color: #000; font-weight: 400; vertical-align: inherit">{{ $t('_xcw._gygs1') }}</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-63ac5a0c" data-id="63ac5a0c" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-6f04947e elementor-widget elementor-widget-image" data-id="6f04947e" data-element_type="widget" data-widget_type="image.default">
              <div class="elementor-widget-container">
                <img style="width: 100%" src="@/assets/com.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="philosophy"
      style="padding-top: 120px;padding-bottom: 20px;"
      class="elementor-section elementor-top-section elementor-element elementor-element-1716c210 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="1716c210"
      data-element_type="section"
      data-settings='{"background_background":"classic","_ha_eqh_enable":false}'>
      <div class="elementor-background-overlay"></div>
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6c5680a1" data-id="6c5680a1" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-6d4bdc8d elementor-widget elementor-widget-heading" data-id="6d4bdc8d" data-element_type="widget" data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">{{ $t('_xcw._wmdl1') }}</h2>
              </div>
            </div>
            <div class="elementor-element elementor-element-267badd5 elementor-widget elementor-widget-heading" data-id="267badd5" data-element_type="widget" data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">
                  {{ $t('_xcw._wmdll1') }}
                </h2>
              </div>
            </div>
            <!-- <div class="elementor-element elementor-element-a98a281 elementor-widget elementor-widget-video" data-id="a98a281" data-element_type="widget" data-settings='{"video_type":"hosted","autoplay":"yes","play_on_mobile":"yes","controls":"yes"}' data-widget_type="video.default">
              <div class="elementor-widget-container">
                <div class="e-hosted-video elementor-wrapper elementor-open-inline">
                  <video class="elementor-video" src="https://shopifyoutlets.com/uploads/video/dropshopping.mp4" autoplay="" controls="" playsinline="" controlslist="nodownload"></video>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>

    <section
      id="dropshopping"
      style="padding-top: 120px"
      class="elementor-section elementor-top-section elementor-element elementor-element-1716c210 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="1716c210"
      data-element_type="section"
      data-settings='{"background_background":"classic","_ha_eqh_enable":false}'>
      <div class="elementor-background-overlay"></div>
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6c5680a1" data-id="6c5680a1" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div style="margin-bottom: 40px;" class="elementor-element elementor-element-6d4bdc8d elementor-widget elementor-widget-heading" data-id="6d4bdc8d" data-element_type="widget" data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">{{ $t('_xcw._smjyjdf') }}</h2>
              </div>
            </div>
            <!-- <div class="elementor-element elementor-element-267badd5 elementor-widget elementor-widget-heading" data-id="267badd5" data-element_type="widget" data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">
                  {{ $t('_xcw._wmdll1') }}
                </h2>
              </div>
            </div> -->
            <div class="elementor-element elementor-element-a98a281 elementor-widget elementor-widget-video" data-id="a98a281" data-element_type="widget" data-settings='{"video_type":"hosted","autoplay":"yes","play_on_mobile":"yes","controls":"yes"}' data-widget_type="video.default">
              <div class="elementor-widget-container">
                <div class="e-hosted-video elementor-wrapper elementor-open-inline">
                  <video class="elementor-video" src="https://shopifyoutlets.com/uploads/video/dropshopping480.mp4" autoplay="" controls="" playsinline="" controlslist="nodownload"></video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="whyus"
      style="padding-top: 120px"
      class="elementor-section elementor-top-section elementor-element elementor-element-08b32d4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="08b32d4"
      data-element_type="section"
      data-settings='{"_ha_eqh_enable":false}'>
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-68ebc24" data-id="68ebc24" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-7418ac2 elementor-widget elementor-widget-heading" data-id="7418ac2" data-element_type="widget" data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">{{ $t('_xcw._wsmxzwm') }} ?</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="elementor-section elementor-top-section elementor-element elementor-element-a4a195f elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default animated slideInUp"
      data-id="a4a195f"
      data-element_type="section"
      data-settings='{"background_background":"classic","animation":"slideInUp","_ha_eqh_enable":false}'>
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-110ec1a1" data-id="110ec1a1" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-2e4b29be elementor-position-left elementor-view-stacked elementor-shape-circle elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
              data-id="2e4b29be"
              data-element_type="widget"
              data-widget_type="icon-box.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-box-wrapper">
                  <div class="elementor-icon-box-icon">
                    <span class="elementor-icon elementor-animation-">
                      <!-- <i aria-hidden="true" class="hm hm-rocket2"></i> -->
                      <img src="@/assets/t1.png" style="width: 30px" alt="" />
                    </span>
                  </div>
                  <div class="elementor-icon-box-content">
                    <h3 class="elementor-icon-box-title">
                      <span>{{ $t('_xcw._gkdwl') }}</span>
                    </h3>
                    <p class="elementor-icon-box-description">{{ $t('_xcw._gkdwl1') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-element elementor-element-41c66428 elementor-position-left elementor-view-stacked elementor-shape-circle elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
              data-id="41c66428"
              data-element_type="widget"
              data-widget_type="icon-box.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-box-wrapper">
                  <div class="elementor-icon-box-icon">
                    <span class="elementor-icon elementor-animation-">
                      <!-- <i aria-hidden="true" class="far fa-credit-card"></i> -->
                      <img src="@/assets/t3.png" style="width: 30px" alt="" />
                    </span>
                  </div>
                  <div class="elementor-icon-box-content">
                    <h3 class="elementor-icon-box-title">
                      <span> {{ $t('_xcw._kszf') }} </span>
                    </h3>
                    <p class="elementor-icon-box-description">{{ $t('_xcw._kszf1') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-641f5cad" data-id="641f5cad" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-65da31cb elementor-widget elementor-widget-image" data-id="65da31cb" data-element_type="widget" data-widget_type="image.default">
              <div class="elementor-widget-container"></div>
            </div>
          </div>
        </div>
        <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-41a6146b" data-id="41a6146b" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div
              class="elementor-element elementor-element-777e5375 elementor-position-left elementor-view-stacked elementor-shape-circle elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
              data-id="777e5375"
              data-element_type="widget"
              data-widget_type="icon-box.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-box-wrapper">
                  <div class="elementor-icon-box-icon">
                    <span class="elementor-icon elementor-animation-">
                      <!-- <i aria-hidden="true" class="hm hm-support-call"></i> -->
                      <img src="@/assets/t2.png" style="width: 30px" alt="" />
                    </span>
                  </div>
                  <div class="elementor-icon-box-content">
                    <h3 class="elementor-icon-box-title">
                      <span> {{ $t('_xcw._zywf') }} </span>
                    </h3>
                    <p class="elementor-icon-box-description">{{ $t('_xcw._zywf1') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="elementor-element elementor-element-4f35f97b elementor-position-left elementor-view-stacked elementor-shape-circle elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
              data-id="4f35f97b"
              data-element_type="widget"
              data-widget_type="icon-box.default">
              <div class="elementor-widget-container">
                <div class="elementor-icon-box-wrapper">
                  <div class="elementor-icon-box-icon">
                    <span class="elementor-icon elementor-animation-">
                      <!-- <i aria-hidden="true" class="hm hm-both-way-arrow"></i> -->
                      <img src="@/assets/t4.png" style="width: 30px" alt="" />
                    </span>
                  </div>
                  <div class="elementor-icon-box-content">
                    <h3 class="elementor-icon-box-title">
                      <span> {{ $t('_xcw._wsbz') }}</span>
                    </h3>
                    <p class="elementor-icon-box-description">{{ $t('_xcw._wsbz1') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 下载app -->
    <section
      id="download"
      style="padding-top: 120px"
      class="elementor-section elementor-top-section elementor-element elementor-element-08b32d4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="08b32d4"
      data-element_type="section"
      data-settings='{"_ha_eqh_enable":false}'>
      <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-68ebc24" data-id="68ebc24" data-element_type="column">
          <div class="elementor-widget-wrap elementor-element-populated">
            <div class="elementor-element elementor-element-7418ac2 elementor-widget elementor-widget-heading" data-id="7418ac2" data-element_type="widget" data-widget_type="heading.default">
              <div class="elementor-widget-container">
                <h2 class="elementor-heading-title elementor-size-default">{{ $t('_xcw._xzcxapp') }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      style="padding-top: 100px; padding-bottom: 100px"
      class="elementor-section elementor-top-section elementor-element elementor-element-a4a195f elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default animated slideInUp"
      data-id="a4a195f"
      data-element_type="section"
      data-settings='{"background_background":"classic","animation":"slideInUp","_ha_eqh_enable":false}'>
      <div class="elementor-container elementor-column-gap-default down-main">
        <div>{{ $t('_xcw._smapp') }}</div>
        <div class="down-box">
          <a :href="$Const.WAP_HOST + '/#/pages/download/android'" target="_blank">
            <div class="down-app">
              <img src="@/assets/app.png" alt="" />
            </div>
          </a>
          <a :href="$Const.WAP_HOST + '/#/pages/download/iphone'" target="_blank">
            <div class="down-app">
              <img src="@/assets/ios.png" alt="" />
            </div>
          </a>
        </div>
        <div class="down-box">
          <div class="down-app">
            <img src="@/assets/app_android.png" alt="" />
          </div>
          <div class="down-app">
            <img src="@/assets/app_ios.png" alt="" />
          </div>
        </div>
      </div>
    </section>

    <!-- 其他 -->
    <section
      id="contact"
      class="elementor-section elementor-top-section elementor-element elementor-element-69c43e68 elementor-section-boxed elementor-section-height-default elementor-section-height-default animated slideInUp"
      data-id="69c43e68"
      data-element_type="section"
      data-settings='{"animation":"slideInUp","_ha_eqh_enable":false}'>
      <div class="elementor-container elementor-column-gap-default">
        <div class="dptp-m" style="margin-bottom: 110px">
          <div class="dpt-fed">{{ $t('_xcw._lxwm12') }}</div>
          <div class="dpt-bit">
            <div style="font-size: 22px">
              {{ $t('_xcw._lxwm13') }}
              <a href="mailto:shopifyoutlets@shopifyoutlets.com" style="color: var(--m-color1)">shopifyoutlets@shopifyoutlets.com</a>
            </div>
          </div>
          <!-- <div class="dpt-box">
            <div class="dpt-lr">
              <div class="dpt-inpt-box"><input type="text" placeholder="* NAME" /></div>
              <div class="dpt-inpt-box"><input type="text" placeholder="* EMAIL" /></div>
              <div class="dpt-inpt-box"><input type="text" placeholder="* PHONE" /></div>
            </div>
            <div class="dpt-lr">
              <div class="dpt-inpt-box">
                <textarea rows="8" placeholder="* YOUR MESSAGE"></textarea>
              </div>
            </div>
          </div>
          <div class="dpt-bt">
            <button>SUBMIT</button>
          </div> -->
        </div>
      </div>
    </section>

    <section class="elementor-section elementor-top-section elementor-element elementor-element-0e6652f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="0e6652f" data-element_type="section" data-settings='{"_ha_eqh_enable":false}'>
      <footer-vue></footer-vue>
    </section>
  </div>
</template>

<script>
import HeadBox from '@/components/HeadBox.vue'
import BannerVue from '@/components/BannerVue.vue'
import FooterVue from '@/components/FooterVue.vue'
export default {
  name: 'IndexVue',
  components: { HeadBox, BannerVue, FooterVue },
  data() {
    return {
      isOpen: false,
      lang: 'en-us'
    }
  },
  created() {
    this.lang = this.$getLangauge()
  },
  mounted() {},
  methods: {
    onDownloadApp() {
      this.$scrollTo('#company', 500)
    },

    onLange(vo) {
      let language = {
        text: vo.title,
        lang: vo.key
      }
      //缓存
      this.lang = vo.key
      this.$setLangauge(language.lang)
      // location.reload();
    },
    onClick(val) {
      this.isOpen = val
      console.log(this.isOpen)
    }
  }
}
</script>

<style scoped src="../assets/css/frontend.css?ver=1.4.3"></style>
<style scoped src="@/assets/css/main.min.css?ver=4.1.3"></style>
<style scoped src="@/assets/css/post-588.css?ver=1681713778"></style>
<style scoped src="@/assets/css/post-9.css?ver=1686130466"></style>
<style scoped src="@/assets/css/frontend-lite.min.css?ver=3.12.1"></style>
<style scoped src="@/assets/css/preset_667.css?ver=23a8f291edff043609365a2d40db131e"></style>
<style scoped src="../assets/css/solid.min.css?ver=5.15.3"></style>
<style scoped src="../assets/css/1.css"></style>
<style scoped src="../assets/css/2.css"></style>
<style src="@/assets/css5/gallery-9g91h.css"></style>

<style scoped>
.down-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}
.down-box {
  width: 100%;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
}
.down-app {
  width: 200px;
}

.dptp-m {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dpt-box {
  width: 100%;
  display: flex;
}
.dpt-lr {
  flex: 1;
  padding: 10px 20px;
}

.dpt-inpt-box {
  padding: 10px 20px;
}

.dpt-inpt-box input {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #fff;
  background: #fbfbfb;
  outline: none;
  padding: 15px 15px;
}

.dpt-inpt-box input:focus {
  border: 1px solid #fff;
  background: #fbfbfb;
}

.dpt-inpt-box textarea {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #fff;
  background: #fbfbfb;
  outline: none;
  padding: 15px 15px;
}

.dpt-inpt-box textarea:focus {
  border: 1px solid #fff;
  background: #fbfbfb;
}

.dpt-fed {
  font-size: 32px;
  color: var(--m-color1) !important;
  font-weight: 700;
  margin-bottom: 20px;
}

.dpt-bit {
  font-size: 34px;
  font-weight: 100;
  margin-bottom: 50px;
}

.dpt-bt {
  padding: 50px;
}

.dpt-bt button {
  padding: 15px 60px;
  border-radius: 25px;
  border: none;
  background: #13b1cd;
  color: #fff;
}

.kxhask {
  flex: 1;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;

  /* 设置文本的颜色为白色，以便于背景渐变的颜色遮罩 */
  color: white;
  /* 设置文本的背景为线性渐变 */
  background: linear-gradient(94deg, var(--m-color1), var(--m-color2));
  /* 使用 blend-mode 实现文本颜色与背景渐变颜色的混合 */
  -webkit-background-clip: text;
  background-clip: text;
  /* 使用 color 作为遮罩，实际看到的是背景渐变 */
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  /* 防止文本阴影遮挡背景渐变 */
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
</style>
