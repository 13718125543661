export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px"><span style="">팀워크</span><span style="">, 고효율 및 실용주의, 긍정적인 혁신, 편안하고 조화로운</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets의 핵심 가치는 적극적인 기업가 정신을 반영합니다. 우리는 막대한 자금, 고등 교육, 휴대폰 배경 지식이 필요하지 않은 새로운 창업 플랫폼이므로 집에서 사업을 시작하고 사장이 될 수 있습니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets 플랫폼은 모든 사람에게 비즈니스 기회를 제공합니다</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2022년 말까지 일일 최고 거래액은 미화 32억 4천만 달러에 달해 총 236만 5천개의 직접 일자리와 완전 고용 기회를 창출할 것입니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets 플랫폼은 전자 상거래를 시작하려는 모든 사람을 위한 장대한 무대를 마련했으며, 음식, 의복, 주택 및 각계각층의 모든 사람을 모아 사업 시작과 부자가 되는 꿈을 실현합니다. 세계 최고의 부자인 빌 게이츠는 21세기는 전자상거래가 이루어지거나 비즈니스가 전혀 이루어지지 않을 것이라고 말했습니다. 홍콩의 부유한 사업가 리카싱(Li Ka-shing)은 전자상거래는 새로운 산업이며, 발생하는 모든 사업 기회는 부자 집단을 창출할 것이라고 말했습니다. 동시에 Heke 전문가들은 비즈니스 모델의 관점에서 볼 때 글로벌 온라인 쇼핑은 결국 통합되고 결합될 것이며 결국 온라인과 오프라인 개발 모델을 통합하고 통합할 것이라고 지적했습니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets 쇼핑 플랫폼은 B2C 모델, C2C 모델, O2O 온라인 및 오프라인 전자상거래 모델을 통합하며, 상품 판매와 온라인 쇼핑을 위한 가맹점을 통합하는 대규모 종합 전자상거래 플랫폼입니다. 유럽의 첨단 크로스보더 전자상거래 온라인 쇼핑 플랫폼입니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><span style="">현재</span> <span style="">글로벌</span> <span style="">소비자</span> <span style="">그룹은</span> <span style="">8천만 명에 이르렀습니다. 이제 우리 회사의 주요 계획은 글로벌 시장을 확장하는 것이므로 현재 성장 여지가 많기 때문에 아이디어를 가진 사람들을 많이 모집해야 합니다. 우리 매장에 합류하기 위해 매장을 오픈하는 것입니다. Shopify Outlets 쇼핑 플랫폼은 전 세계의 판매자 회원과 개인을 진심으로 초대하여 우리와 함께하고, 다른 사람들의 지혜를 모으고, 공동의 꿈을 실현하도록 초대합니다. 이미 사업을 시작 중이거나 길을 잃었더라도 Shopify Outlets 쇼핑몰 플랫폼은 귀하의 기업가적 꿈을 실현할 수 있는 무대를 제공할 것입니다. 회사는 전자 상거래에 관심이 있는 사람들이 Shopify Outlets 쇼핑몰 플랫폼에 가입하는 것을 따뜻하게 환영합니다. 당신의 성공적인 경력을 기원합니다!</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets 판매자가 되어 사업을 시작하는 방법 </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px"><span style="">먼저</span> <span style="">iPhone 또는 Google Play의 App Store를 통해 Shopify Outlets 소프트웨어를 다운로드해야 합니다. 다운로드가 완료된 후 자신에게 맞는 등록 방법을 선택하고 플랫폼에서 매장을 찾아 등록하십시오. 클릭하여 실제 정보를 입력하고 플랫폼을 검토하고 승인한 후 로그인할 수 있습니다. 이제 판매자 센터를 클릭하여 제품 판매를 시작하는 방법을 살펴보겠습니다. 판매자 센터에 들어가서 제품 목록을 클릭한 다음 창고에서 제품 추가를 클릭하세요. 공급자에 의해. 판매하려는 제품을 선택한 후 한 번의 클릭으로 &quot;판매용 매장에 추가&quot;를 클릭하면 매장 오픈 준비가 완료됩니다. 귀하의 상점이 오픈 준비가 되었습니다. 귀하는 플랫폼에서 상점을 꾸밀 수 있습니다. 판매하는 상품의 주문 가격은 공급자가 설정하며, 플랫폼에서 판매하는 상품의 가격에 따라서만 판매할 수 있습니다. Shopify Outlets 판매자로서 우리는 매장을 방문하는 고객을 잘 관리하고 서비스를 제공해야 합니다. 그렇지 않으면 매장의 신용 점수 및 벌금에 영향을 미치는 불만 사항이 발생할 수 있습니다.</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets에서 판매자가 되기 위한 돈 버는 과정</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify 아웃렛, 공급업체, 판매자 및 소비자가 각각 수행하는 역할</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets는 플랫폼 캐리어를 제공합니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. 공급업체는 Shopify Outlets에 제품을 제공합니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. 판매자는 Shopify Outlets에 매장을 열고 플랫폼 공급업체의 제품을 자체 매장에 게시합니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. 소비자는 가맹점에서 광고와 트래픽을 통해 자신이 좋아하는 제품을 확인한 후 구매합니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. 판매자는 상품 주문을 확인한 후 플랫폼에서 해당 상품을 구매합니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. 공급자는 주문에 따라 자동으로 상품을 배송합니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. 소비자가 상품을 받으면 Shopify Outlets는 판매자에게 대금을 정산하고 판매자는 이익을 얻습니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify 아울렛Wish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets는 소비자가 구매하는 모든 제품의 품질을 약속하고 모든 판매자가 정착 후 매우 좋은 경험을 할 수 있도록 보장합니다. Shopify Outlets는 세계에서 또 다른 새로운 유형의 국경 간 전자 상거래 거대 기업이 되어 최대 수출 규모로 성장할 것입니다. B2C 전자상거래 플랫폼입니다. 전자상거래 판매자로 일찍 참여할수록 돈을 벌기가 더 쉬워집니다. Shopify Outlets는 해외 전자상거래 기업가의 참여를 환영합니다. Shopify Outlets를 선택하는 것은 부를 선택하는 것을 의미합니다.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><span style="">좋아요</span><span style="">, 이상이 사업 소개입니다. 창업 여정을 시작하고 빠르게 행동하세요!</span></span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`