export default {
  '_xcw._sy': '首页',
  '_xcw._scsy': '商城首页',
  '_xcw._wskdlc': '网上开店流程',
  '_xcw._whxcwm': '为何选择Shopify Outlets',

  '_xcw._hdzx': '活动中心',
  '_xcw._wsj': '万圣节',

  '_xcw._gsjj': '公司简介',
  '_xcw._qyss': '企业优势',
  '_xcw._wlys': '物流优势',
  '_xcw._ptys': '平台优势',
  '_xcw._gygs': '关于公司',
  '_xcw._tdjs': '团队介绍',
  '_xcw._wmdll': '我们的理念',
  '_xcw._xcsf': '为什么选择我们',

  '_xcw._sjrz': '商家入驻',
  '_xcw._sjrz1': '商家入驻',

  '_xcw._xzapp': '下载APP',
  '_xcw._xzios': 'IOS 下载',
  '_xcw._xzaz': 'Android 下载',

  '_xcw._lxwm': '联系我们',
  '_xcw._lxwm1': '联系我们',

  '_xcw._qqswhz': '打造全球一站式购物平台',
  '_xcw._syxsqd': '使用 ShopifyOutlets 在线、离线和其他任何地方进行销售。',
  '_xcw._mfrz': '开始免费入驻',
  '_xcw._srdzyj': '输入电子邮件地址',
  '_xcw._rzbsty': '免费入驻 ShopifyOutlets。 输入您的电子邮件地址，即表示您同意接收来自 ShopifyOutlets 的营销电子邮件。',

  '_xcw._bt1': '团队协作',
  '_xcw._bt2': '高效务实',
  '_xcw._bt3': '积极创新',
  '_xcw._bt4': '轻松和谐',

  '_xcw._dkjt0': 'Shopify Outlets的核心价值观也是我们积极创业精神的体现，我们是一个全新的创业平台，不需要巨额资金，不需要高学历，不需要手机背景，就可以实现在家创业当老板。',
  '_xcw._dkjt1': 'Shopify Outlets购物平台集B2C模式、C2C模式、O2O线上线下电子商务模式为一体，集商家进行商品销售、网购为一体的大型综合性电子商务平台。',
  '_xcw._dkjt2': 'Shopify Outlets平台为每一个想开始使用电子商务的人搭建了一个宏伟的舞台，汇聚了吃、穿、住、行各行各业的每个人，实现我们创业致富的梦想。',

  '_xcw._ydgd': '阅读更多',
  '_xcw._wskd': '网上开店流程',
  '_xcw._lxkf': '联系客服服务',
  '_xcw._sqkd': '申请开店',
  '_xcw._spsj': '商品上架出售',
  '_xcw._hdddls': '获取订单利润',

  '_xcw._xzdly': '选择 ShopifyOutlets 的九大理由',
  '_xcw._zlysx': '我们致力于在互联网电商的趋势下能够快速实现盈利的新手。',
  '_xcw._0ykd': '免费开店',
  '_xcw._ptbsfy': '在平台上开店不收取任何费用',
  '_xcw._spgy': '商品供应',
  '_xcw._jxtx': '全球供应为您精心挑选产品',
  '_xcw._zjxs': '直销',
  '_xcw._qqfw': '提供全球直运服务',
  '_xcw._llzc': '流量支持',
  '_xcw._qsxd': '拥有 900M 活跃用户，轻松下单',
  '_xcw._kfzc': '客服服务支持',
  '_xcw._txzcn': 'Shopify Outlets 为您提供 7*24 小时的商家服务',
  '_xcw._shfw': '售后服务',
  '_xcw._dcbth': '协助商户提供售后服务，降低退货成本',
  '_xcw._3tkh': '3天内开户',
  '_xcw._bznsj': '帮助您快速开店、上架产品',
  '_xcw._7tdg': '7 天内订购',
  '_xcw._sjltl': '对新商家有流量支持，可以快速下单。',
  '_xcw._ptfyd': '订单利润高',
  '_xcw._ddlsg': '订单利润高达70%',

  '_xcw._lhcsr': 'ShopifyOutlets 联合创始人 — John Furner',
  '_xcw._csrjs':
    "John Furner 是一位美国企业高管。沃尔玛美国公司前总裁兼首席执行官 Furner 曾领导沃尔玛的山姆会员店子公司，于 1974 年出生于阿肯色州杰克逊维尔。就读于阿肯色大学费耶特维尔分校，学习营销管理。Furner 于 1996 年毕业于 Sam Walton 商学院。他于 1993 年在沃尔玛开始了他的职业生涯，担任小时店员。他后来成为商店经理，然后成为区域经理。在担任 Sam's Club 的首席营销官后，他于 2017 年 2 月 1 日成为 Sam's Club 的首席执行官。在此之前，他曾在沃尔玛国际公司工作了近三年，担任沃尔玛中国商品和营销执行副总裁。.Furner 于 2019 年 11 月成为沃尔玛美国总裁兼首席执行官。现任沃尔玛首席营销官兼 ShopifyOutlets 联合创始人",

  '_xcw._qyys1':
    '从成立时的5人小团队，发展到在北美设有5个办事处、拥有4,000多人的世界级团队；Shopify Outlets一直致力于为每个梦想跨境“商店”业务的人创造一个更美好的世界。营商环境，不断收获丰富的国际市场实践经验和资源，以及对海外市场开发运作的洞察，使Shopify Outlets享有今天的国际地位；现在，我们已经成立美国、加拿大、印度、日本、澳大利亚、英国、德国、法国、意大利、瑞典、荷兰、波兰等团队，为全球的各种商家和零售商提供服务。贸易业务是通往海外市场的桥梁。我们全方位、点对点地赋能不同的“店铺”商家，利用我们的国际实力帮助他们拓展全球业务，并在瞬息万变的世界市场中形成良性购物循环，帮助赢得客户对他们产品的喜爱和品牌忠诚度。',
  '_xcw._wlsysm':
    '智能物流控股有限公司成立于2013年5月。Shopify Outlets独立运营的数字物流枢纽eHub、首选仓、中心仓、海外仓等，可以保证跨境包裹的物流时效。配送中心、终端卡网络、自建自提柜等物流设施不断落地，具备国际运输能力。关务、海外配送等能力可以帮助进出口商家解决在仓储、运输、清关、配送等方面遇到的问题，为美国和全球商家提供国际出口物流、国际供应链、国际货运三大领域的服务。在国际物流方面，Shopify Outlets致力于将更多合作伙伴整合到其物流网络中。截至2022年，Shopify Outlets网络中的跨境物流合作伙伴数量已达到89个，包括燕文、四方、新加坡邮政、英国邮政、ZTO、YTO、EMS、IC、斑马等，其物流覆盖范围可达全球224个国家/地区的231个跨境仓库，构建了真正具有全球配送能力的跨境物流网络。2022年，Shopify Outlets将与卡塔尔航空货运推出中巴包机，并与阿特拉斯航空共同开通欧洲、美国、中国和东南亚的专用航线。',

  '_xcw._ptysd1':
    'Shopify Outlets平台流量很大。总流量为900M。每个Shopify Outlets网站都是其当地的一个巨大的在线购物平台，世界各地都有网站，允许全球所有国家的卖家向世界销售商品。Shopify Outlets平台最关注客户的购物体验，尤其是消费者。例如，美国站点拥有1.01亿会员客户，人均年消费额为1200美元。Shopify OutletsFreight拥有超强的物流仓储管理系统FBA，是全球最大的物流系统;它的交货速度极快，并且具有非常大的存储容量。Shopify Outlets平台标准是最标准化的。无论是第三方卖家还是Shopify Outlets直营店，它们都共存共售。没有销售垄断。只要产品销量好，后来加入的卖家可以迅速占据畅销榜单。Shopify Outlets平台的销售利润率最大。Shopify Outlets平台上的客户是中高端消费者，因此他们不太在乎价格，可以达到20%甚至70%的利润标准。这也是通过结合Shopify Outlets平台的功能来实现的一种效果，让Shopify Outlets卖家获得更多利润。现阶段，Shopify Outlets平台仍处于流量红利期，平台正在全球扩张。卖家要做的就是追随Shopify Outlets的脚步，将卖家的产品推广到国外，与世界接轨，成为世界知名品牌。大家都说，在做跨境电商业务时，“选择大于努力”。在选择电商平台时，无论是从市场容量、平台标准、发展空间，还是卖家追求的完美毛利率和利润空间来看，Shopify Outlets似乎都是跨境电商卖家值得优先考虑的平台。',

  '_xcw._gygs1':
    'Shopify Outlets 成立于 2011 年，公司总部位于美国。Shopify Outlets 海外和电子商务平台覆盖200多个国家和地区的消费者，包括核心站点：美国、加拿大、印度、日本、澳大利亚、英国、德国、法国、意大利、瑞典、荷兰、波兰等消费者，只需在手机或网页上打开Shopify Outlets应用程序， 切换到国家或地区，您可以轻松享受购买。',

  '_xcw._wmdl1': '我们的理念',
  '_xcw._wmdll1':
    'Shopify Outlets  致力于推动“正宗、实惠、按需”在线商品的普及，帮助更多消费者享受海量丰富的在线商品，获得更高的生活品质;通过提供在线销售平台等基础服务，帮助更多的企业开拓市场，打造品牌，实现产业升级;帮助更多有梦想的人通过互联网实现创业和就业。新商业文明下的 Shopify Outlets 正在朝着创造 1000 万个工作岗位的下一个目标迈进。',
  '_xcw._wsmxzwm': '为什么选择我们',
  '_xcw._gkdwl': '更快的物流',
  '_xcw._gkdwl1': '全球各大城市均设有仓库和配送人员，采用就近配送原则。提高交付效率和客户满意度。',
  '_xcw._kszf': '快速付款',
  '_xcw._kszf1': '我们有更快的付款方式。除了一般的银行卡支付外，我们还支持虚拟货币交易，例如：USTD 等',
  '_xcw._zywf': '专业的客户服务',
  '_xcw._zywf1': '我们拥有专业的客服团队，全天 24 小时在线服务。世界上所有主要语言都可以无障碍交流',
  '_xcw._wsbz': '完善的售后保障',
  '_xcw._wsbz1': '完善的举报制度和退货政策，保护消费者的合法权益',
  '_xcw._xzcxapp': '下载应用程序',
  '_xcw._smapp': '扫描二维码下载移动应用',
  '_xcw._lhcsrs': '联合创始人',

  '_xcw._lxwm12': '联系我们',
  '_xcw._lxwm13': '在 Shopify Outlets，我們重視您對我們組織的興趣，但由於收到的直接請求數量龐大，我們無法單獨回覆所有請求。 有任何疑問，請聯絡我們。官方信箱:',

  '_xcw._wzbt': '我们是Shopify Outlets平台',

  '_xcw._wz': require('./docs/zh-hans.js').default,

  '_xcw._smjyjdf': '什么是一件代发'
}
