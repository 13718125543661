export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">チームワーク、高効率と実用主義、前向きな革新、リラックスした調和</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outletsの中核となる価値観は、当社の積極的な起業家精神の反映でもあります。私たちは、莫大な資金、高学歴、携帯電話の経歴を必要としない、まったく新しい起業家プラットフォームです。そのため、自宅でビジネスを始めて上司になることができます。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets プラットフォームはすべての人にビジネスチャンスをもたらします</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2022年末までに、1日の取引高のピークは32億4,000万米ドルに達し、合計236万5,000人の直接雇用と完全雇用の機会が創出されると予想されます。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outletsプラットフォームは、eコマースの利用を始めたいすべての人に晴れ舞台を用意し、衣食住、あらゆる階層の人々が集まり、起業して金持ちになるという私たちの夢を実現します。世界で最も裕福なビル・ゲイツは、21世紀は電子商取引が行われるか、まったくビジネスがなくなるかのどちらかになるだろうと述べた。香港の裕福な実業家、李嘉誠氏は、電子商取引は新しい産業であり、ビジネスチャンスが生まれるたびに富裕層のグループが生まれるだろうと述べた。同時に、Hekeの専門家は、ビジネスモデルの観点から、世界のオンラインショッピングは最終的には統合および統合され、最終的にはオンラインとオフラインの開発モデルが統合および統合されるだろうと指摘しました。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outletsショッピングプラットフォームは、B2Cモデル、C2Cモデル、O2Oのオンラインとオフラインの電子商取引モデルを統合し、商品販売とオンラインショッピングの販売者を統合する、オンラインとオフラインの広告情報を統合する大規模な総合電子商取引プラットフォームです。ヨーロッパの先進的な越境電子商取引オンライン ショッピング プラットフォームの普及。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><span style="font-family:微软雅黑">現在、世界の消費者層は</span><span style="font-family:微软雅黑">8,000万人に達しており、当社の主な計画は世界市場の拡大であり、現在成長の余地が大きいため、アイデアを持った人材を大量に採用する必要があります。出店のご案内Shopify Outlets ショッピング プラットフォームは、世界中のマーチャント メンバーや個人を心から歓迎し、私たちに参加し、他の人の知恵を出し合い、共通の夢を実現してください。すでにビジネスを始めている人でも、道に迷っている人でも、Shopify アウトレット モール プラットフォームは、起業家としての夢を実現するための舞台を提供します。当社は、e コマースに興味のある人々が Shopify アウトレット モール プラットフォームに参加することを温かく歓迎します。あなたの将来の成功を祈っています！</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets マーチャントになってビジネスを始める方法</span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px"><span style="font-family:微软雅黑">まず、</span><span style="font-family:微软雅黑">iPhone の App Store または Google Play から Shopify Outlets ソフトウェアをダウンロードする必要があります。ダウンロードが完了したら、自分に合った登録方法を選択し、プラットフォームでストアを見つけて登録し、実際の情報を入力して申請を送信します。プラットフォームが審査され承認された後、ログインできます。再びマーチャント センターをクリックして、マーチャントになる方法を確認してください。マーチャント センターにアクセスし、商品リストをクリックして、[倉庫から商品を追加] をクリックします。サプライヤーによる。販売したい商品を選択し、ワンクリックで「販売のためにストアに追加」をクリックすると、ストアを開く準備が整います。ストアを開く準備ができました。販売する商品の注文価格はサプライヤーによって設定され、プラットフォーム上で販売する商品の価格に従ってのみ販売できます。 Shopify Outletsの加盟店として、私たちは店舗に来る顧客に配慮し、適切なサービスを提供する必要があります。そうしないと、店舗の信用スコアやペナルティに影響を与える苦情が発生する可能性があります。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify アウトレットの販売者になるための収益プロセス</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets、サプライヤー、マーチャント、消費者がそれぞれ果たす役割</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outletsはプラットフォームキャリアを提供します</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. サプライヤーはShopify Outletsに製品を提供します</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. 販売者は Shopify Outlets に店舗を開き、プラットフォーム上のサプライヤーからの商品を自分の店舗に公開します。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. 消費者は、広告やトラフィックを通じて加盟店でお気に入りの商品を見つけ、購入します。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. 販売者は商品注文を確認し、プラットフォーム上で対応する商品を購入します。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. サプライヤーは注文に従って商品を自動的に出荷します。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. 消費者が商品を受け取ると、Shopify Outletsは販売者への支払いを決済し、販売者は利益を得ます。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify アウトレットウィッシュ</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outletsは、消費者が購入するあらゆる製品の品質を保証し、すべての販売者が慣れた後に非常に良い体験を提供できることを保証します。Shopify Outletsは、世界のもう一つの新しいタイプの越境電子商取引の巨人となり、最大の輸出企業に成長するでしょう。 B2C 電子商取引プラットフォーム。 eコマース販売者として早く参加するほど、収益を得るのが容易になります。 Shopify Outlets は、海外の e コマース起業家が参加することを歓迎します。Shopify Outlets を選択することは、富を選択することを意味します。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">さて、上記はビジネスの紹介です。起業の旅を始めて、すぐに行動してください。</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
