export default {
  '_xcw._sy': '首頁',
  '_xcw._scsy': '商城首頁',
  '_xcw._wskdlc': '線上開店流程',
  '_xcw._whxcwm': '為何選擇Shopify Outlets',

  '_xcw._hdzx': '活動中心',
  '_xcw._wsj': '萬聖節',

  '_xcw._gsjj': '公司簡介',
  '_xcw._qyss': '企業優勢',
  '_xcw._wlys': '物流優勢',
  '_xcw._ptys': '平台優勢',
  '_xcw._gygs': '關於公司',
  '_xcw._tdjs': '團隊介紹',
  '_xcw._wmdll': '我們的理念',
  '_xcw._xcsf': '為什麼選擇我們',

  '_xcw._sjrz': '商家入駐',
  '_xcw._sjrz1': '商家入駐',

  '_xcw._xzapp': '下載APP',
  '_xcw._xzios': 'IOS 下載',
  '_xcw._xzaz': 'Android 下載',

  '_xcw._lxwm': '聯絡我們',
  '_xcw._lxwm1': '聯絡我們',

  '_xcw._qqswhz': '打造全球一站式購物平台',
  '_xcw._syxsqd': '使用 ShopifyOutlets 在線上、離線和其他任何地方進行銷售。 ',
  '_xcw._mfrz': '開始免費進駐',
  '_xcw._srdzyj': '輸入電子郵件地址',
  '_xcw._rzbsty': '免費入駐 ShopifyOutlets。 輸入您的電子郵件地址，即表示您同意接收來自 ShopifyOutlets 的行銷電子郵件。 ',

  '_xcw._bt1': '團隊協作',
  '_xcw._bt2': '高效率務實',
  '_xcw._bt3': '積極創新',
  '_xcw._bt4': '輕鬆和諧',

  '_xcw._dkjt0': 'Shopify Outlets的核心價值也是我們積極創業精神的體現，我們是一個全新的創業平台，不需要巨額資金，不需要高學歷，不需要手機背景，就可以實現在家創業當老闆。 ',
  '_xcw._dkjt1': 'Shopify Outlets購物平台集B2C模式、C2C模式、O2O線上線下電子商務模式為一體，集商家進行商品銷售、網購為一體的大型綜合電子商務平台。 ',
  '_xcw._dkjt2': 'Shopify Outlets平台為每一個想開始使用電子商務的人搭建了一個宏偉的舞台，匯聚了吃、穿、住、行各行各業的每個人，實現我們創業致富的夢想。 ',

  '_xcw._ydgd': '閱讀更多',
  '_xcw._wskd': '線上開店流程',
  '_xcw._lxkf': '聯絡客服服務',
  '_xcw._sqkd': '申請開店',
  '_xcw._spsj': '商品上架出售',
  '_xcw._hdddls': '取得訂單利潤',

  '_xcw._xzdly': '選擇 ShopifyOutlets 的九大理由',
  '_xcw._zlysx': '我們致力於在互聯網電商的趨勢下能夠快速實現盈利的新手。 ',
  '_xcw._0ykd': '免費開店',
  '_xcw._ptbsfy': '在平台上開店不收取任何費用',
  '_xcw._spgy': '商品供應',
  '_xcw._jxtx': '全球供應為您精心挑選產品',
  '_xcw._zjxs': '直銷',
  '_xcw._qqfw': '提供全球直運服務',
  '_xcw._llzc': '流量支援',
  '_xcw._qsxd': '擁有 900M 活躍用戶，輕鬆下單',
  '_xcw._kfzc': '客服服務支援',
  '_xcw._txzcn': 'Shopify Outlets 為您提供 7*24 小時的商家服務',
  '_xcw._shfw': '售後服務',
  '_xcw._dcbth': '協助商家提供售後服務，降低退貨成本',
  '_xcw._3tkh': '3天內開戶',
  '_xcw._bznsj': '幫助您快速開店、上架產品',
  '_xcw._7tdg': '7 天內訂購',
  '_xcw._sjltl': '對新商家有流量支持，可以快速下單。 ',
  '_xcw._ptfyd': '訂單利潤高',
  '_xcw._ddlsg': '訂單利潤高達70%',

  '_xcw._lhcsr': 'ShopifyOutlets 共同創辦人 — John Furner',
  '_xcw._csrjs': "John Furner 是一位美國企業高管。沃爾瑪美國公司前總裁兼首席執行官Furner 曾領導沃爾瑪的山姆會員店子公司，於1974 年出生於阿肯色州傑克遜維爾。就讀於阿肯色大學費耶特維爾分校，學習行銷管理。行銷長後，他於2017 年2 月1 日成為Sam's Club 的執行長。年11 月成為沃爾瑪美國總裁兼執行長。",

  '_xcw._qyys1':
    '從成立時的5人小團隊，發展到在北美設有5個辦事處、擁有4,000多人的世界級團隊；Shopify Outlets一直致力於為每個夢想跨境「商店」業務的人創造一個更美好的世界。營商環境，不斷收穫豐富的國際市場實踐經驗和資源，以及對海外市場開發運作的洞察，使Shopify Outlets享有今天的國際地位；現在，我們已經成立美國、加拿大、印度、日本、澳大利亞、英國、德國、法國、義大利、瑞典、荷蘭、波蘭等團隊，為全球的各種商家和零售商提供服務。貿易業務是通往海外市場的橋樑。我們全方位、點對點地賦能不同的「店鋪」商家，利用我們的國際實力幫助他們拓展全球業務，並在瞬息萬變的世界市場中形成良性購物循環，幫助贏得客戶對他們產品的喜愛和品牌忠誠度。 ',
  '_xcw._wlsysm':
    '智能物流控股有限公司成立於2013年5月。 Shopify Outlets獨立營運的數位物流樞紐eHub、首選倉、中心倉、海外倉等，可確保跨國包裹的物流時效。配送中心、終端卡網路、自建自提櫃等物流設施不斷落地，具備國際運輸能力。關務、海外配送等能力可以幫助進出口商家解決在倉儲、運輸、清關、配送等方面遇到的問題，為美國和全球商家提供國際出口物流、國際供應鏈、國際貨運三大領域的服務。在國際物流方面，Shopify Outlets致力於將更多合作夥伴整合到其物流網路中。截至2022年，Shopify Outlets網路中的跨國物流合作夥伴數量已達89個，包括燕文、四方、新加坡郵政、英國郵政、ZTO、YTO、EMS、IC、斑馬等，其物流覆蓋範圍可達全球224個國家的231個跨國倉庫，建構了真正具有全球配送能力的跨國物流網絡。 2022年，Shopify Outlets將與卡達航空貨運推出中巴包機，並與阿特拉斯航空共同開通歐洲、美國、中國和東南亞的專用航線。 ',

  '_xcw._ptysd1':
    'Shopify Outlets平台流量很大。總流量為900M。每個Shopify Outlets網站都是其當地的一個巨大的線上購物平台，世界各地都有網站，允許全球所有國家的賣家向世界銷售商品。 Shopify Outlets平台最重視顧客的購物體驗，尤其是消費者。例如，美國站點擁有1.01億會員客戶，人均每年消費額為1,200美元。 Shopify OutletsFreight擁有超強的物流倉儲管理系統FBA，是全球最大的物流系統;它的交貨速度極快，並且具有非常大的存儲容量。 Shopify Outlets平台標準是最標準化的。無論是第三方賣家還是Shopify Outlets直營店，它們都共存共售。沒有銷售壟斷。只要產品銷售好，後來加入的賣家可以快速佔據暢銷名單。 Shopify Outlets平台的銷售利潤率最大。 Shopify Outlets平台上的客戶是中高端消費者，因此他們不太在乎價格，可以達到20%甚至70%的利潤標準。這也是透過結合Shopify Outlets平台的功能來實現的效果，讓Shopify Outlets賣家獲得更多利潤。現階段，Shopify Outlets平台仍處於流量紅利期，平台正在全球擴張。賣家要做的就是跟隨Shopify Outlets的腳步，將賣家的產品推廣到國外，與世界接軌，成為世界知名品牌。大家都說，在做跨國電商業務時，「選擇大於努力」。在選擇電商平台時，無論是從市場容量、平台標準、發展空間，或是賣家追求的完美毛利率和利潤空間來看，Shopify Outlets似乎都是跨境電商賣家值得優先考慮的平台。 ',

  '_xcw._gygs1':
    'Shopify Outlets 成立於 2011 年，公司總部位於美國。 Shopify Outlets 海外和電子商務平台覆蓋200多個國家和地區的消費者，包括核心站點：美國、加拿大、印度、日本、澳大利亞、英國、德國、法國、義大利、瑞典、荷蘭、波蘭等消費者，只需在手機或網頁上開啟Shopify Outlets應用程序， 切換到國家或地區，您可以輕鬆享受購買。 ',

  '_xcw._wmdl1': '我們的理念',
  '_xcw._wmdll1':
    'Shopify Outlets 致力於推動「正宗、實惠、按需」線上商品的普及，幫助更多消費者享受大量豐富的線上商品，獲得更高的生活品質;透過提供線上銷售平台等基礎服務，幫助更多的企業開拓市場，打造品牌，實現產業升級;幫助更多有夢想的人透過網路創業就業。新商業文明下的 Shopify Outlets 正在朝著創造 1,000 萬個工作機會的下一個目標邁進。 ',
  '_xcw._wsmxzwm': '為什麼選擇我們',
  '_xcw._gkdwl': '更快的物流',
  '_xcw._gkdwl1': '全球各大城市均設有倉庫及配送人員，採用就近配送原則。提高交付效率和客戶滿意度。 ',
  '_xcw._kszf': '快速付款',
  '_xcw._kszf1': '我們有更快的付款方式。除了一般的銀行卡支付外，我們還支援虛擬貨幣交易，例如：USTD 等',
  '_xcw._zywf': '專業的顧客服務',
  '_xcw._zywf1': '我們擁有專業的客服團隊，全天 24 小時線上服務。世界上所有主要語言都可以無障礙溝通',
  '_xcw._wsbz': '完善的售後保障',
  '_xcw._wsbz1': '完善的舉報制度和退貨政策，保護消費者的合法權益',
  '_xcw._xzcxapp': '下載應用程式',
  '_xcw._smapp': '掃描二維碼下載行動應用程式',
  '_xcw._lhcsrs': '共同創辦人',

  '_xcw._lxwm12': '聯絡我們',
  '_xcw._lxwm13': '在 Shopify Outlets，我們重視您對我們組織的興趣，但由於收到的直接請求數量龐大，我們無法單獨回复所有請求。 有任何疑問，請聯絡我們。官方信箱:',

  '_xcw._wzbt': '我們是Shopify Outlets平台',

  '_xcw._wz': require('./docs/zh-hant.js').default,

  '_xcw._smjyjdf': '什麼是一件代發?'
}
