export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Ryhmätyö, korkea tehokkuus ja käytännöllisyys, positiivinen innovaatio, rento ja harmoninen</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outletsin perusarvot heijastavat myös proaktiivista yrittäjyyttämme. Olemme upouusi yrittäjyysalusta, joka ei vaadi suuria varoja, korkeakoulutusta tai matkapuhelintaustaa, joten voit aloittaa yrityksen ja olla pomo kotona.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets -alusta tarjoaa liiketoimintamahdollisuuksia kaikille</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Vuoden 2022 loppuun mennessä yhden päivän transaktiovolyymi saavuttaa 3,24 miljardia dollaria, mikä luo yhteensä 2,365 miljoonaa suoraa työpaikkaa ja täystyöllisyyden mahdollisuuksia.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets -alusta on asettanut suuren näyttämön kaikille, jotka haluavat aloittaa sähköisen kaupankäynnin, tuoden yhteen kaikki ruoasta, vaatteista, asumisesta ja kaikilta elämänaloilta toteuttamaan unelmamme yrityksen perustamisesta ja rikastumisesta. Bill Gates, maailman rikkain mies, sanoi, että 2000-luku tulee olemaan joko sähköistä kaupankäyntiä tai ei liiketoimintaa ollenkaan. Varakas hongkongilainen liikemies Li Ka-shing sanoi, että sähköinen kaupankäynti on uusi toimiala, ja jokainen avautuva liiketoimintamahdollisuus luo ryhmän rikkaita ihmisiä. Samalla Heken asiantuntijat huomauttivat, että liiketoimintamallin näkökulmasta globaali verkkokauppa tulee lopulta yhtenäiseksi ja yhdistetyksi ja lopulta yhtenäistämään ja integroimaan online- ja offline-kehitysmalleja.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets -ostosalusta yhdistää B2C-mallin, C2C-mallin ja O2O-verkkokaupan mallit. Se on laajamittainen kattava verkkokauppa-alusta, joka integroi kauppiaat tuotteiden myyntiin ja verkkokauppaan levityksen mukavuuspalvelut Euroopan edistynyt rajat ylittävä sähköisen kaupankäynnin verkkokauppa.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Tällä hetkellä globaali kuluttajaryhmä on saavuttanut 80 miljoonaa ihmistä Nyt yrityksemme pääsuunnitelmana on laajentaa globaaleja markkinoita, joten kasvunvaraa on tällä hetkellä paljon, joten meidän on rekrytoitava suuri määrä ihmisiä, joilla on idea. myymälän avaamisesta liittyäksesi myymäläämme. Shopify Outlets -ostosalusta kutsuu vilpittömästi kauppiasjäseniä ja yksityishenkilöitä kaikkialta maailmasta liittymään joukkoomme, yhdistämään muiden viisautta ja toteuttamaan yhteiset unelmamme. Olitpa jo aloittamassa yritystä tai eksynyt, Shopify Outlets -ostoskeskus tarjoaa sinulle mahdollisuuden toteuttaa yrittäjäunelmasi. Yritys toivottaa sähköisestä kaupankäynnistä kiinnostuneet ihmiset liittymään Shopify Outlets -ostoskeskukseen. toivottaa menestystä tulevaisuuteen!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets -kauppiaaksi ryhtyminen ja yrityksen perustaminen </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Ensin meidän on ladattava Shopify Outlets -ohjelmisto App Storesta iPhonessa tai Google Playssa. Kun lataus on valmis, valitse sinulle sopiva rekisteröintitapa ja rekisteröi tili. Täytä oikeat tietosi ja lähetä hakemus Napsauta nyt Merchant Centeriä nähdäksesi kuinka voit aloittaa tuotteiden myymisen. Siirry Merchant Centeriin, napsauta tuoteluetteloa ja napsauta sitten Lisää tuotteita varastosta toimittajien toimesta. Voit valita tuotteet, joita haluat myydä, ja klikata &quot;Lisää myyntiin myyntiin&quot; yhdellä napsautuksella, ja myymäläsi on valmis avautumaan. Myymäläsi on valmis avattavaksi. Voit sisustaa myymälääsi alustalla myymiesi tavaroiden tilaushinnan määrää toimittaja, ja voit myydä niitä vain alustalla myymiesi tavaroiden hinnan mukaan. Shopify Outlets -kauppiaana meidän tulee huolehtia myymäläämme tulevista asiakkaista ja palvella heitä hyvin, muuten voi tulla valituksia, jotka vaikuttavat myymälän luottopisteisiin ja sakkoihin.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Kauppiaaksi tulemisen prosessi Shopify Outletsissa</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outletsin, tavarantoimittajien, kauppiaiden ja kuluttajien roolit</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets tarjoaa alustan harjoittajat</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Toimittajat toimittavat tuotteita Shopify Outletsille</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Kauppiaat avaavat myymälöitä Shopify Outletsissa ja julkaisevat toimittajien tuotteita alustalla omiin myymälöihinsä.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Kuluttajat näkevät suosikkituotteensa kauppiaissa mainosten ja liikenteen kautta ja tekevät sitten ostoksia.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. Kauppias näkee tavaratilauksen ja ostaa sitten vastaavat tuotteet alustalta</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Toimittaja lähettää tavarat automaattisesti tilauksen mukaan.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Kun kuluttaja vastaanottaa tavarat, Shopify Outlets suorittaa maksun kauppiaalle, ja kauppias tekee voittoa.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify OutletsWish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets lupaa kaikkien kuluttajien ostamien tuotteiden laadun ja varmistaa, että kaikilla kauppiailla on erittäin hyvä kokemus asumisen jälkeen. Shopify Outletsista tulee toinen uudenlainen rajat ylittävä verkkokaupan jättiläinen maailmassa ja kasvaa suurimmaksi vientiyritykseksi. B2C sähköisen kaupankäynnin alusta. Mitä aikaisemmin osallistut verkkokaupan myyjänä, sitä helpompi on ansaita rahaa. Shopify Outlets toivottaa ulkomaiset verkkokauppayrittäjät tervetulleiksi mukaan. Shopify Outletsin valitseminen tarkoittaa varallisuuden valitsemista.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Okei, yllä oleva on yrityksen esittely, aloita yrittäjämatkasi ja toimi nopeasti!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
