import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAnimateOnScroll from "vue-animate-onscroll";
Vue.use(VueAnimateOnScroll);

// -------------------------------------
//i18n
import i18n from "@/lang";
import { setLangauge, getLangauge } from "@/lang";
//设置函数
Vue.prototype.$getLangauge = getLangauge;
Vue.prototype.$setLangauge = setLangauge;

//const
import Const from '@/utils/const'
Vue.prototype.$Const = Const

//scroll-to
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);


//antd组件
import { Dropdown, Icon, Button } from "ant-design-vue";
Vue.use(Dropdown);
Vue.use(Icon);
Vue.use(Button);
import "ant-design-vue/dist/antd.css";

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
