export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Praca zespołowa, wysoka wydajność i pragmatyzm, pozytywne innowacje, zrelaksowany i harmonijny</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Podstawowe wartości Shopify Outlets są również odzwierciedleniem naszego proaktywnego ducha przedsiębiorczości. Jesteśmy zupełnie nową platformą przedsiębiorczości, która nie wymaga ogromnych funduszy, wyższego wykształcenia ani znajomości telefonu komórkowego, dzięki czemu możesz rozpocząć działalność gospodarczą i być szefem w domu.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Platforma Shopify Outlets zapewnia każdemu możliwości biznesowe</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Do końca 2022 r. szczytowy wolumen jednodniowych transakcji osiągnie 3,24 miliarda dolarów, tworząc łącznie 2,365 miliona bezpośrednich miejsc pracy i możliwości pełnego zatrudnienia.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Platforma Shopify Outlets przygotowała wspaniałą scenę dla każdego, kto chce zacząć korzystać z handlu elektronicznego, łącząc wszystkich, począwszy od żywności, odzieży, mieszkań i wszystkich środowisk, aby zrealizować nasze marzenie o założeniu firmy i wzbogaceniu się. Bill Gates, najbogatszy człowiek świata, powiedział, że XXI wiek będzie albo handlem elektronicznym, albo całkowitym brakiem biznesu. Li Ka-shing, zamożny biznesmen z Hongkongu, powiedział, że handel elektroniczny to nowa branża i każda pojawiająca się szansa biznesowa stworzy grupę bogatych ludzi. Jednocześnie eksperci Heke zwrócili uwagę, że z punktu widzenia modelu biznesowego globalne zakupy online docelowo zostaną ujednolicone i połączone, a docelowo ujednolicą i zintegrują modele rozwoju online i offline.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Platforma zakupowa Shopify Outlets integruje model B2C, model C2C oraz modele handlu elektronicznego online i offline O2O. Jest to kompleksowa platforma e-commerce na dużą skalę, która integruje sprzedawców w zakresie sprzedaży produktów i zakupów online. Integruje informacje reklamowe online i offline wygodne usługi rozpowszechniania, zaawansowana europejska platforma zakupów online w zakresie transgranicznego handlu elektronicznego.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">W chwili obecnej globalna grupa konsumentów liczy już 80 milionów osób. Teraz głównym planem naszej firmy jest ekspansja na rynek globalny, więc obecnie jest dużo miejsca na rozwój, dlatego musimy pozyskać dużą liczbę osób, które mają pomysł. otwarcia sklepu, aby dołączyć do naszego sklepu. Platforma zakupowa Shopify Outlets serdecznie zaprasza sprzedawców i osoby prywatne z całego świata do przyłączenia się do nas, dzielenia się wiedzą innych i realizowania naszych wspólnych marzeń. Niezależnie od tego, czy rozpoczynasz już działalność gospodarczą, czy zgubiłeś drogę, platforma centrum handlowego Shopify Outlets zapewni Ci scenę do realizacji Twoich marzeń związanych z przedsiębiorczością. Firma serdecznie wita osoby zainteresowane handlem elektronicznym, aby dołączyły do platformy centrum handlowego Shopify Outlets życzymy udanej kariery!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Jak zostać sprzedawcą Shopify Outlets i rozpocząć działalność gospodarczą </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Najpierw musimy pobrać oprogramowanie Shopify Outlets za pośrednictwem App Store na iPhone&#39;a lub Google Play. Po zakończeniu pobierania wybierz odpowiadającą Ci metodę rejestracji i zarejestruj konto. Znajdź sklep na platformie i zarejestruj się. Kliknij, aby podać swoje prawdziwe dane i przesłać wniosek. Po sprawdzeniu i zatwierdzeniu platformy możesz się zalogować kliknijmy teraz Merchant Center, aby zobaczyć, jak zostać sprzedawcą. Jak rozpocząć sprzedaż swoich produktów? Wejdź do Merchant Center, kliknij listę produktów, a następnie kliknij opcję Dodaj produkty z magazynu. Wszystkie produkty są tutaj publikowane przez dostawców. Możesz wybrać produkty, które chcesz sprzedawać, a następnie jednym kliknięciem kliknąć „Dodaj do swojego sklepu na sprzedaż” i Twój sklep jest gotowy do otwarcia. Twój sklep jest gotowy do otwarcia. Możesz udekorować swój sklep na platformie. Cena zamówienia sprzedawanych towarów jest ustalana przez dostawcę i możesz je sprzedawać wyłącznie zgodnie z ceną towarów, które sprzedajesz na platformie. Jako sprzedawca Shopify Outlets musimy dbać o klientów, którzy przychodzą do naszego sklepu i dobrze ich obsługiwać, w przeciwnym razie mogą pojawić się reklamacje, które będą miały wpływ na zdolność kredytową sklepu i kary.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Proces zarabiania pieniędzy poprzez zostanie sprzedawcą w Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Role odgrywane odpowiednio przez Shopify Outlets, dostawców, sprzedawców i konsumentów</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets zapewnia platformy przewoźników</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Dostawcy dostarczają produkty do Shopify Outlets</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Sprzedawcy otwierają sklepy w Shopify Outlets i publikują produkty od dostawców na platformie we własnych sklepach.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Konsumenci widzą swoje ulubione produkty w sklepach handlowych poprzez reklamy i ruch, a następnie dokonują zakupów.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. Sprzedawca widzi zamówienie na towar, a następnie dokonuje zakupu odpowiednich produktów na platformie</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Dostawca automatycznie wysyła towar zgodnie z zamówieniem.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Gdy konsument otrzyma towar, Shopify Outlets ureguluje płatność na rzecz sprzedawcy, a sprzedawca osiągnie zysk.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify OutletsWish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets obiecuje jakość każdego produktu kupowanego przez konsumentów i zapewnia, że wszyscy sprzedawcy będą mieli bardzo dobre doświadczenia po zadomowieniu się. Shopify Outlets stanie się kolejnym nowym typem transgranicznego giganta handlu elektronicznego na świecie i stanie się największym eksporterem Platforma handlu elektronicznego B2C. Im wcześniej dołączysz do grona sprzedawców e-commerce, tym łatwiej będzie Ci zarobić pieniądze. Shopify Outlets zaprasza zagranicznych przedsiębiorców zajmujących się handlem elektronicznym do przyłączenia się. Wybór Shopify Outlets oznacza wybór bogactwa.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">OK, powyższe jest wprowadzeniem biznesowym, rozpocznij swoją podróż w zakresie przedsiębiorczości i działaj szybko!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
