export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Kerja tim, efisiensi tinggi dan pragmatisme, inovasi positif, santai dan harmonis</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Nilai-nilai inti Shopify Outlets juga merupakan cerminan dari semangat kewirausahaan proaktif kami. Kami adalah platform kewirausahaan baru yang tidak memerlukan dana besar, pendidikan tinggi, atau latar belakang ponsel, sehingga Anda dapat memulai bisnis dan menjadi bos di rumah.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Platform Shopify Outlets menghadirkan peluang bisnis bagi semua orang</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Pada akhir tahun 2022, puncak volume transaksi satu hari akan mencapai US$3,24 miliar, menciptakan total 2,365 juta lapangan kerja langsung dan peluang kerja penuh.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Platform Shopify Outlets telah menyiapkan panggung besar bagi semua orang yang ingin mulai menggunakan e-commerce, menyatukan semua orang mulai dari makanan, pakaian, perumahan, dan semua lapisan masyarakat untuk mewujudkan impian kita memulai bisnis dan menjadi kaya. Bill Gates, orang terkaya di dunia, mengatakan bahwa abad ke-21 akan menjadi era e-commerce atau tidak ada bisnis sama sekali. Li Ka-shing, seorang pengusaha kaya asal Hong Kong, mengatakan bahwa e-commerce adalah industri baru, dan setiap peluang bisnis yang muncul akan menciptakan sekelompok orang kaya. Pada saat yang sama, para ahli Heke menunjukkan bahwa dari perspektif model bisnis, belanja online global pada akhirnya akan disatukan dan digabungkan, dan pada akhirnya akan menyatukan dan mengintegrasikan model pengembangan online dan offline.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Platform belanja Shopify Outlets mengintegrasikan model B2C, model C2C, dan model e-commerce online dan offline O2O. Ini adalah platform e-commerce komprehensif berskala besar yang mengintegrasikan pedagang untuk penjualan produk dan belanja online layanan kenyamanan penyebaran. Platform belanja online e-commerce lintas batas yang canggih di Eropa.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Saat ini, kelompok konsumen global telah mencapai 80 juta orang. Sekarang rencana utama perusahaan kami adalah memperluas pasar global, jadi saat ini terdapat banyak ruang untuk pertumbuhan, jadi kami perlu merekrut banyak orang yang memiliki ide tersebut. membuka toko untuk bergabung dengan toko kami. Platform belanja Shopify Outlets dengan tulus mengundang anggota pedagang dan individu dari seluruh dunia untuk bergabung dengan kami, menyatukan kebijaksanaan orang lain, dan mewujudkan impian kita bersama. Baik Anda sudah memulai bisnis atau tersesat, platform mal Shopify Outlets akan memberi Anda panggung untuk mewujudkan impian wirausaha Anda. Perusahaan dengan hangat menyambut orang-orang yang tertarik dengan e-commerce untuk bergabung dengan platform mal Shopify Outlets dan semoga karier Anda sukses di masa depan!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Cara menjadi merchant Shopify Outlets dan memulai bisnis </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Pertama kita perlu mendownload software Shopify Outlets melalui App Store di iPhone atau Google Play. Setelah pengunduhan selesai, pilih metode pendaftaran yang sesuai untuk Anda dan daftarkan akun. Temukan toko di platform dan daftar. Klik untuk mengisi informasi asli Anda dan mengirimkan aplikasi. Setelah platform ditinjau dan disetujui, Anda dapat login masuk lagi. Sekarang mari klik Merchant Center untuk melihat cara menjadi pedagang. Bagaimana cara mulai menjual produk Anda? Masuk ke Merchant Center, klik daftar produk, lalu klik Tambah Produk dari Gudang oleh pemasok. Anda dapat memilih produk yang ingin Anda jual, lalu klik &quot;Tambahkan ke toko Anda untuk dijual&quot; dengan satu klik, dan toko Anda siap dibuka. Toko Anda siap dibuka. Anda dapat mendekorasi toko Anda di platform. Harga pesanan barang yang Anda jual ditentukan oleh pemasok, dan Anda hanya dapat menjualnya sesuai dengan harga barang yang Anda jual di platform. Sebagai pedagang Shopify Outlets, kita perlu menjaga pelanggan yang datang ke toko kita dan melayani mereka dengan baik, jika tidak, mungkin akan ada keluhan yang mempengaruhi nilai kredit dan denda toko.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Proses menghasilkan uang dengan menjadi pedagang di Outlet Shopify</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Peran yang dimainkan masing-masing oleh Outlet Shopify, pemasok, pedagang, dan konsumen</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets menyediakan operator platform</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Pemasok menyediakan produk ke Outlet Shopify</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Pedagang membuka toko di Outlet Shopify dan mempublikasikan produk dari pemasok di platform ke toko mereka sendiri.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Konsumen melihat produk favoritnya di toko merchant melalui iklan dan lalu lintas, kemudian melakukan pembelian.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. Pedagang melihat pesanan barang dan kemudian membeli produk terkait di platform</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Pemasok secara otomatis mengirimkan barang sesuai pesanan.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Ketika konsumen menerima barang, Shopify Outlets akan melunasi pembayaran kepada pedagang, dan pedagang akan mendapat keuntungan.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Outlet ShopifyWish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets menjanjikan kualitas setiap produk yang dibeli oleh konsumen dan memastikan bahwa semua pedagang akan mendapatkan pengalaman yang sangat baik setelah menetap. Shopify Outlets akan menjadi raksasa e-commerce lintas batas jenis baru di dunia dan tumbuh menjadi ekspor terbesar Platform e-niaga B2C. Semakin awal Anda berpartisipasi sebagai penjual e-commerce, semakin mudah menghasilkan uang. Shopify Outlets menyambut pengusaha e-commerce luar negeri untuk bergabung. Memilih Outlet Shopify berarti memilih kekayaan.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Oke, di atas adalah pengenalan bisnis, mulailah perjalanan wirausaha Anda dan bertindak cepat!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
