export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">团队协作、高效务实、积极创新、轻松和谐</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets的核心价值观也是我们积极创业精神的体现。我们是一个全新的创业平台，不需要巨额资金，不需要高学历，不需要手机背景，就可以实现在家创业当老板。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets平台为大家带来商机</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><span style="font-family:微软雅黑">到</span><span style="font-family:微软雅黑">2022年底，单日交易额峰值将达到32.4亿美元，共创造236.5万个直接就业和充分就业机会</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets平台为每一个想开始使用电子商务的人搭建了一个宏伟的舞台，汇聚了吃、穿、住、行各行各业的每个人，实现我们创业致富的梦想。世界首富比尔·盖茨表示，21世纪要么是电子商务，要么根本就没有生意。香港富商李嘉诚表示，电子商务是一个新行业，每一个商机的出现都会造就一批富人。同时和客专家指出，从商业模式上看，全球网络购物最终将统一与结合，最终将统一与线上线下相结合的发展模式。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets购物平台集B2C模式、C2C模式、O2O线上线下电子商务模式为一体，集商家进行商品销售、网购为一体的大型综合性电子商务平台，广告信息传播 便利服务 线上线下合二为一 欧洲先进的跨境电子商务网购平台。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><span style="font-family:微软雅黑">目前全球的消费群体已经达到了</span><span style="font-family:微软雅黑">8000万人，现在我们公司的主要计划是开拓全球市场，所以目前有很大的增长空间，所以我们需要招募大量有开店想法的人 加入我们的商店。Shopify Outlets购物平台诚邀来自世界各地的商户会员和个人加入我们，汇聚他人的智慧，实现我们共同的梦想。无论您是已经在创业，还是迷失了创业之路，Shopify Outlets商城平台都将为您提供实现创业梦想的舞台公司热忱欢迎有志于电商的人士加入Shopify Outlets商城平台，并祝愿 你有一个成功的未来！</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px"><span style="font-family:微软雅黑">如何成为</span><span style="font-family:微软雅黑">Shopify Outlets商家并开展业务</span></span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px"><span style="font-family:微软雅黑">首先我们需要通过</span><span style="font-family:微软雅黑">iPhone或Google Play的App Store下载Shopify Outlets软件。下载完成后选择适合您的注册方式并注册账号在平台内找到店铺并注册后点击填写您的真实信息并提交申请等待平台审核通过后即可再次登录，现在让我们点击商家中心，看看成为商家后如何开始销售您的产品 进入商家中心，点击产品列表，再点击从仓库添加商品&nbsp;这里的所有产品均由供应商发布。您可以选择您想要销售的产品，然后一键点击“添加到您的商店进行销售”，您的商店就可以开业了。您的店铺即可开业您可以在平台上装修您的店铺，您所售商品的订单价格由供货商设定，只能按照平台对您所售商品的价格进行出售。作为Shopify Outlets商家我们需要照顾好来到我们店铺的顾客并服务好他们，否则可能会有投诉影响店铺信用评分和处罚。</span></span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px"><span style="font-family:微软雅黑">在</span><span style="font-family:微软雅黑">Shopify Outlets成为商家的赚钱流程</span></span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets、供应商、商家、消费者分别扮演的角色</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets提供平台载体</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2.供应商提供产品到Shopify Outlets</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3.商家在Shopify Outlets开设店铺，将平台上供应商的产品发布到自己店铺</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4.消费者通过广告，流量在商家店铺看到喜欢的产品，然后发生购买行为</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5.商家看到货物订单，然后在平台采购相应的产品</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6.供应商按照订单自动发货</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7.消费者收到货物，Shopify Outlets将会把货款结算到商家，商家实现盈利</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets愿望</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets承诺消费者购买的任何产品品质，保证所有商家入驻之后会有一个非常好的体验感，Shopify Outlets将会成为世界上又一个新型的跨境电商巨头，成长为最大出口 B2C 电商平台。越早参与电商卖家，越容易赚钱。Shopify Outlets欢迎广大海外电商创业人员加入，选择Shopify Outlets就是选择财富。</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">好了以上就是业务介绍了，开始你的创业之路赶快行动吧！</span>
</p>`
