export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Teamwork, høj effektivitet og pragmatisme, positiv innovation, afslappet og harmonisk</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets&#39; kerneværdier er også en afspejling af vores proaktive iværksætterånd. Vi er en helt ny iværksætterplatform, der ikke kræver store midler, høj uddannelse eller mobiltelefonbaggrund, så du kan starte en virksomhed og være chef derhjemme.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets-platformen bringer forretningsmuligheder til alle</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Ved udgangen af 2022 vil det maksimale transaktionsvolumen på én dag nå op på 3,24 milliarder USD, hvilket vil skabe i alt 2,365 millioner direkte job og fulde beskæftigelsesmuligheder.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets-platformen har skabt en stor scene for alle, der ønsker at begynde at bruge e-handel, og samler alle fra mad, tøj, bolig og alle samfundslag for at realisere vores drøm om at starte en virksomhed og blive rig. Bill Gates, verdens rigeste mand, sagde, at det 21. århundrede vil være enten e-handel eller ingen forretning overhovedet. Li Ka-shing, en velhavende Hongkong-forretningsmand, sagde, at e-handel er en ny industri, og hver forretningsmulighed, der opstår, vil skabe en gruppe rige mennesker. Samtidig påpegede Heke-eksperter, at fra forretningsmodellens perspektiv vil global online shopping med tiden blive forenet og kombineret og i sidste ende vil forene og integrere online og offline udviklingsmodeller.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets-indkøbsplatformen integrerer B2C-modellen, C2C-modellen og O2O online og offline e-handelsmodeller. Det er en omfattende e-handelsplatform, der integrerer forhandlere til produktsalg og online-shopping formidlings bekvemmelighedstjenester Europas avancerede grænseoverskridende e-handel online shopping platform.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">På nuværende tidspunkt har den globale forbrugergruppe nået 80 millioner mennesker. Nu er vores virksomheds hovedplan at udvide det globale marked, så der er i øjeblikket meget plads til vækst, så vi skal rekruttere et stort antal mennesker, der har ideen. at åbne en butik for at blive en del af vores butik. Shopify Outlets shoppingplatform inviterer oprigtigt købmandsmedlemmer og enkeltpersoner fra hele verden til at slutte sig til os, samle andres visdom og realisere vores fælles drømme. Uanset om du allerede starter en virksomhed eller er gået vild, vil Shopify Outlets indkøbscenter platform give dig en scene til at realisere dine iværksætterdrømme ønsker dig en succesfuld fremtid!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Sådan bliver du Shopify Outlets-handler og starter en virksomhed </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Først skal vi downloade Shopify Outlets-softwaren gennem App Store på iPhone eller Google Play. Når overførslen er fuldført, skal du vælge den registreringsmetode, der passer til dig, og registrere en konto på platformen og registrere dig. Klik for at udfylde dine rigtige oplysninger og indsende ansøgningen ind igen. Lad os nu klikke på Merchant Center for at se, hvordan man begynder at sælge dine produkter af leverandører. Du kan vælge de produkter, du vil sælge, og derefter klikke på &quot;Tilføj til din butik til salg&quot; med et enkelt klik, og din butik er klar til at åbne. Din butik er klar til at åbne Du kan indrette din butik på platformen Ordreprisen på de varer, du sælger, er fastsat af leverandøren, og du kan kun sælge dem i henhold til prisen på de varer, du sælger på platformen. Som Shopify Outlets-handler skal vi tage os af de kunder, der kommer til vores butik, og betjene dem godt, ellers kan der være klager, der påvirker butikkens kreditscore og bøder.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Pengeprocessen med at blive købmand i Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">De roller, der spilles af henholdsvis Shopify Outlets, leverandører, forhandlere og forbrugere</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets tilbyder platformsbærere</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Leverandører leverer produkter til Shopify Outlets</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Sælgere åbner butikker i Shopify Outlets og udgiver produkter fra leverandører på platformen til deres egne butikker.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Forbrugere ser deres yndlingsprodukter i købmandsforretninger gennem reklamer og trafik og foretager derefter indkøb.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. Forretningen ser vareordren og køber derefter de tilsvarende produkter på platformen</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Leverandøren sender automatisk varerne i henhold til ordren.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Når forbrugeren modtager varerne, afregner Shopify Outlets betalingen til forretningen, og forhandleren vil opnå en fortjeneste.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify OutletsWish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets lover kvaliteten af ethvert produkt købt af forbrugere og sikrer, at alle forhandlere får en meget god oplevelse efter at have fundet sig til rette. Shopify Outlets bliver endnu en ny type grænseoverskridende e-handelsgigant i verden og vokser til den største eksportvare. B2C e-handelsplatform. Jo tidligere du deltager som e-handelssælger, jo lettere er det at tjene penge. Shopify Outlets byder oversøiske e-handel-iværksættere velkommen til at deltage.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Okay, ovenstående er forretningsintroduktionen, start din iværksætterrejse og handle hurtigt!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
