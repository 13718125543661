export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Lavoro di squadra, alta efficienza e pragmatismo, innovazione positiva, rilassata e armoniosa</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">I valori fondamentali di Shopify Outlets riflettono anche il nostro spirito imprenditoriale proattivo. Siamo una piattaforma imprenditoriale nuova di zecca che non richiede fondi enormi, istruzione superiore o esperienza nel settore della telefonia mobile, quindi puoi avviare un&#39;impresa ed essere il capo a casa.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">La piattaforma Shopify Outlets offre opportunità di business a tutti</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Entro la fine del 2022, il volume massimo di transazioni in un solo giorno raggiungerà i 3,24 miliardi di dollari, creando un totale di 2,365 milioni di posti di lavoro diretti e piene opportunità di occupazione.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">La piattaforma Shopify Outlets ha allestito un grande palcoscenico per tutti coloro che vogliono iniziare a utilizzare l&#39;e-commerce, riunendo tutti, dal cibo, all&#39;abbigliamento, all&#39;alloggio e tutti i ceti sociali, per realizzare il nostro sogno di avviare un&#39;impresa e arricchirsi. Bill Gates, l’uomo più ricco del mondo, ha affermato che il 21° secolo sarà o l’e-commerce o l’assenza totale di business. Li Ka-shing, un ricco uomo d&#39;affari di Hong Kong, ha affermato che l&#39;e-commerce è un settore nuovo e che ogni opportunità di business che si presenta creerà un gruppo di persone ricche. Allo stesso tempo, gli esperti di Heke hanno sottolineato che, dal punto di vista del modello di business, lo shopping online globale finirà per essere unificato e combinato e alla fine unificherà e integrerà i modelli di sviluppo online e offline.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">La piattaforma di shopping Shopify Outlets integra il modello B2C, il modello C2C e i modelli di e-commerce online e offline O2O. È una piattaforma di e-commerce completa su larga scala che integra i commercianti per la vendita di prodotti e lo shopping online. Integra informazioni pubblicitarie online e offline servizi di comodità di diffusione.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Allo stato attuale, il gruppo di consumatori globale ha raggiunto 80 milioni di persone. Ora il piano principale della nostra azienda è quello di espandere il mercato globale, quindi attualmente c&#39;è molto spazio per la crescita, quindi dobbiamo reclutare un gran numero di persone che abbiano l&#39;idea. di aprire un negozio per unirsi al nostro negozio. La piattaforma di shopping Shopify Outlets invita sinceramente i membri commercianti e gli individui di tutto il mondo a unirsi a noi, condividere la saggezza degli altri e realizzare i nostri sogni comuni. Che tu stia già avviando un&#39;impresa o abbia perso la strada, la piattaforma del centro commerciale Shopify Outlets ti fornirà un palcoscenico per realizzare i tuoi sogni imprenditoriali. L&#39;azienda accoglie calorosamente le persone interessate all&#39;e-commerce che vogliono unirsi alla piattaforma del centro commerciale Shopify Outlets ti augura un futuro di successo!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Come diventare un commerciante di Shopify Outlets e avviare un&#39;attività </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Per prima cosa dobbiamo scaricare il software Shopify Outlets tramite l&#39;App Store su iPhone o Google Play. Una volta completato il download, scegli il metodo di registrazione adatto a te e registra un account Trova il negozio nella piattaforma e registrati Fai clic per inserire le tue informazioni reali e inviare la domanda Dopo che la piattaforma è stata esaminata e approvata, puoi accedere di nuovo. Ora facciamo clic su Merchant Center per vedere come diventare un commerciante. Come iniziare a vendere i tuoi prodotti? Accedi al Merchant Center, fai clic sull&#39;elenco dei prodotti, quindi fai clic su Aggiungi prodotti dal magazzino dai fornitori. Puoi selezionare i prodotti che desideri vendere, quindi fare clic su &quot;Aggiungi al tuo negozio per la vendita&quot; con un clic e il tuo negozio è pronto per essere aperto. Il tuo negozio è pronto per l&#39;apertura. Puoi decorare il tuo negozio sulla piattaforma. Il prezzo dell&#39;ordine dei beni che vendi è stabilito dal fornitore e puoi venderli solo in base al prezzo dei beni che vendi sulla piattaforma. In qualità di commerciante di Shopify Outlets, dobbiamo prenderci cura dei clienti che vengono nel nostro negozio e servirli bene, altrimenti potrebbero esserci reclami che influiscono sul punteggio di credito e sulle sanzioni del negozio.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Il processo di guadagno per diventare un commerciante in Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">I ruoli svolti rispettivamente da Shopify Outlet, fornitori, commercianti e consumatori</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets fornisce operatori di piattaforma</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. I fornitori forniscono prodotti ai Shopify Outlet</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. I commercianti aprono negozi in Shopify Outlets e pubblicano i prodotti dei fornitori sulla piattaforma nei propri negozi.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. I consumatori vedono i loro prodotti preferiti nei negozi dei commercianti attraverso la pubblicità e il traffico, e poi effettuano gli acquisti.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. Il commerciante vede l&#39;ordine della merce e poi acquista i prodotti corrispondenti sulla piattaforma</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Il fornitore spedisce automaticamente la merce in base all&#39;ordine.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Quando il consumatore riceve la merce, Shopify Outlets liquiderà il pagamento al commerciante e il commerciante realizzerà un profitto.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Punti vendita ShopifyWish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets promette la qualità di qualsiasi prodotto acquistato dai consumatori e garantisce che tutti i commercianti vivano un&#39;ottima esperienza dopo essersi sistemati. Shopify Outlets diventerà un altro nuovo tipo di gigante dell&#39;e-commerce transfrontaliero nel mondo e diventerà il più grande esportatore Piattaforma di commercio elettronico B2C. Prima partecipi come venditore di e-commerce, più facile sarà guadagnare soldi. Shopify Outlets invita gli imprenditori dell&#39;e-commerce esteri a unirsi. Scegliere Shopify Outlets significa scegliere la ricchezza.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Ok, quanto sopra è la presentazione dell&#39;attività, inizia il tuo percorso imprenditoriale e agisci rapidamente!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
