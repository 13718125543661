export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Teamwork, efficiency and pragmatism, active innovation, relaxed and harmonious</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">The core values of Shopify Outlets also reflect our positive entrepreneurial spirit. We are a brand-new entrepreneurial platform that does not require huge capital, high education, or a mobile phone background to realize starting a business at home.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets platform brings business opportunities to everyone</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">By the end of 2022, the peak daily transaction volume will reach US$3.24 billion, creating a total of 2.365 million direct jobs and full employment opportunities.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">The Shopify Outlets platform has built a grand stage for everyone who wants to start e-commerce, bringing together everyone from all walks of life, including food, clothing, housing, and transportation, to realize our dream of starting a business and getting rich. Bill Gates, the world&#39;s richest man, said that the 21st century is either e-commerce or no business at all. Hong Kong tycoon Li Ka-shing said that e-commerce is a new industry, and the emergence of every business opportunity will create a group of rich people. At the same time, Heke experts pointed out that from the perspective of business model, global online shopping will eventually unify and combine the development model of online and offline.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets shopping platform integrates B2C model, C2C model, O2O online and offline e-commerce model. It is a large-scale comprehensive e-commerce platform that integrates merchants to sell goods and online shopping. It integrates advertising information dissemination convenience services online and offline and is an advanced cross-border e-commerce online shopping platform in Europe.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">At present, the global consumer group has reached 80 million people. Now our company&#39;s main plan is to expand the global market, so there is a lot of room for growth, so we need to recruit a large number of people who have the idea of opening a store to join our store. Shopify Outlets shopping platform sincerely invites merchant members and individuals from all over the world to join us, gather the wisdom of others, and realize our common dream. Whether you are already starting a business or lost your way to entrepreneurship, Shopify Outlets shopping platform will provide you with a stage to realize your entrepreneurial dream. The company warmly welcomes people who are interested in e-commerce to join Shopify Outlets shopping platform, and wish you a successful future!</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">How to become a Shopify Outlets merchant and start a business </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">First, we need to download the Shopify Outlets software through the App Store on iPhone or Google Play. After downloading, choose the registration method that suits you and register an account. Find a store on the platform and register. Click to fill in your real information and submit your application. Wait for the platform to review and approve it before logging in again. Now let&#39;s click on the Merchant Center to see how to start selling your products after becoming a merchant. Enter the Merchant Center, click the product list, and then click Add Items from Warehouse. All products here are published by suppliers. You can choose the products you want to sell, and then click &quot;Add to your store for sale&quot; with one click, and your store can be opened. Your store is open. You can decorate your store on the platform. The order price of the goods you sell is set by the supplier, and can only be sold according to the price of the goods sold by the platform. As a Shopify Outlets merchant, we need to take good care of the customers who come to our store and serve them well, otherwise there may be complaints that affect the store&#39;s credit score and penalties.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">How to make money as a merchant in Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">The roles played by Shopify Outlets, suppliers, merchants, and consumers</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1. Shopify Outlets provides a platform carrier</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Suppliers provide products to Shopify Outlets</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Merchants open stores in Shopify Outlets and publish products from suppliers on the platform to their own stores</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Consumers see their favorite products in the store through advertisements and traffic, and then make purchases</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. The merchant sees the goods order and then purchases the corresponding products on the platform</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Suppliers automatically ship according to orders</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. When consumers receive the goods, Shopify Outlets will settle the payment to the merchant, and the merchant will make a profit</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets Wishlist</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets promises consumers the quality of any product they buy, and guarantees that all merchants will have a very good experience after entering. Shopify Outlets will become another new cross-border e-commerce giant in the world and grow into the largest export B2C e-commerce platform. The earlier you participate in e-commerce, the easier it is to make money. Shopify Outlets welcomes overseas e-commerce entrepreneurs to join. Choosing Shopify Outlets is choosing wealth.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Well, the above is an introduction to the business. Let’s start your entrepreneurial journey now!</span>
</p>`
