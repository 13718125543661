export default `<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Travail d&#39;équipe, haute efficacité et pragmatisme, innovation positive, détendu et harmonieux</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Les valeurs fondamentales de Shopify Outlets reflètent également notre esprit entrepreneurial proactif. Nous sommes une toute nouvelle plateforme entrepreneuriale qui ne nécessite pas de fonds énormes, d&#39;études supérieures ou d&#39;expérience en téléphonie mobile, vous pouvez donc démarrer une entreprise et être le patron à la maison.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">La plateforme Shopify Outlets offre des opportunités commerciales à tous</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">D&#39;ici fin 2022, le volume maximal de transactions sur une seule journée atteindra 3,24 milliards de dollars américains, créant un total de 2,365 millions d&#39;emplois directs et des opportunités de plein emploi.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">La plateforme Shopify Outlets a ouvert une grande scène pour tous ceux qui souhaitent commencer à utiliser le commerce électronique, réunissant tous les acteurs de l&#39;alimentation, de l&#39;habillement, du logement et de tous les horizons pour réaliser notre rêve de créer une entreprise et de devenir riche. Bill Gates, l&#39;homme le plus riche du monde, a déclaré que le 21e siècle serait soit celui du commerce électronique, soit celui de l&#39;absence d&#39;affaires du tout. Li Ka-shing, un riche homme d&#39;affaires de Hong Kong, a déclaré que le commerce électronique est une nouvelle industrie et que chaque opportunité commerciale qui se présente créera un groupe de personnes riches. Dans le même temps, les experts de Heke ont souligné que, du point de vue du modèle commercial, les achats en ligne mondiaux seront finalement unifiés et combinés, et qu&#39;à terme, ils unifieront et intégreront les modèles de développement en ligne et hors ligne.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:18px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">La plate-forme d&#39;achat Shopify Outlets intègre le modèle B2C, le modèle C2C et les modèles de commerce électronique en ligne et hors ligne O2O. Il s&#39;agit d&#39;une plate-forme de commerce électronique complète à grande échelle qui intègre les commerçants pour la vente de produits et les achats en ligne. services de commodité de diffusion. Plateforme avancée d&#39;achat en ligne de commerce électronique transfrontalier d&#39;Europe.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">À l&#39;heure actuelle, le groupe mondial de consommateurs compte 80 millions de personnes. Le principal plan de notre entreprise est désormais d&#39;élargir le marché mondial. Il existe donc actuellement une grande marge de croissance et nous devons donc recruter un grand nombre de personnes ayant cette idée. d&#39;ouvrir une boutique pour rejoindre notre magasin. La plateforme d&#39;achat Shopify Outlets invite sincèrement les commerçants membres et les particuliers du monde entier à nous rejoindre, à mettre en commun la sagesse des autres et à réaliser nos rêves communs. Que vous démarriez déjà une entreprise ou que vous vous soyez égaré, la plateforme du centre commercial Shopify Outlets vous offrira une scène pour réaliser vos rêves d&#39;entrepreneur. L&#39;entreprise accueille chaleureusement les personnes intéressées par le commerce électronique pour rejoindre la plateforme du centre commercial Shopify Outlets et. vous souhaite un avenir professionnel réussi !</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Comment devenir un marchand Shopify Outlets et démarrer une entreprise </span></strong><span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><span style="color: rgb(44, 62, 80);font-size: 18px">Nous devons d&#39;abord télécharger le logiciel Shopify Outlets via l&#39;App Store sur iPhone ou Google Play. Une fois le téléchargement terminé, choisissez la méthode d&#39;inscription qui vous convient et créez un compte. Trouvez le magasin sur la plateforme et inscrivez-vous. Cliquez pour remplir vos informations réelles et soumettre la candidature. Une fois la plateforme examinée et approuvée, vous pouvez vous connecter. Cliquez à nouveau sur Merchant Center pour voir comment devenir marchand. Comment commencer à vendre vos produits ? Entrez dans Merchant Center, cliquez sur la liste des produits, puis sur Ajouter des produits depuis l&#39;entrepôt. Tous les produits ici sont publiés. par les fournisseurs. Vous pouvez sélectionner les produits que vous souhaitez vendre, puis cliquer sur « Ajouter à votre boutique à vendre » en un clic, et votre boutique est prête à ouvrir. Votre magasin est prêt à ouvrir. Vous pouvez décorer votre magasin sur la plateforme. Le prix de commande des produits que vous vendez est fixé par le fournisseur, et vous ne pouvez les vendre qu&#39;en fonction du prix des produits que vous vendez sur la plateforme. En tant que commerçant Shopify Outlets, nous devons prendre soin des clients qui viennent dans notre magasin et bien les servir, sinon il pourrait y avoir des plaintes qui affecteraient le pointage de crédit et les pénalités du magasin.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px"><br/></span><strong><span style="color: rgb(44, 62, 80);font-size: 18px">Le processus pour gagner de l’argent pour devenir commerçant dans Shopify Outlets</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Les rôles joués respectivement par Shopify Outlets, les fournisseurs, les commerçants et les consommateurs</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">1.Shopify Outlets fournit des supports de plateforme</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">2. Les fournisseurs fournissent des produits aux magasins Shopify</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">3. Les commerçants ouvrent des magasins dans Shopify Outlets et publient les produits des fournisseurs sur la plateforme dans leurs propres magasins.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">4. Les consommateurs voient leurs produits préférés dans les magasins à travers la publicité et le trafic, puis effectuent des achats.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">5. Le commerçant voit la commande de marchandises puis achète les produits correspondants sur la plateforme</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">6. Le fournisseur expédie automatiquement les marchandises conformément à la commande.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">7. Lorsque le consommateur recevra les marchandises, Shopify Outlets réglera le paiement au commerçant et celui-ci réalisera un bénéfice.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">&nbsp;</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <strong><span style="color: rgb(44, 62, 80);font-size: 18px">Shopify OutletsWish</span></strong>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">Shopify Outlets promet la qualité de tout produit acheté par les consommateurs et garantit que tous les commerçants vivront une très bonne expérience une fois installés. Shopify Outlets deviendra un autre nouveau type de géant du commerce électronique transfrontalier au monde et deviendra le plus grand exportateur. Plateforme de commerce électronique B2C. Plus vous participez tôt en tant que vendeur de commerce électronique, plus il est facile de gagner de l&#39;argent. Shopify Outlets accueille les entrepreneurs du commerce électronique étrangers. Choisir Shopify Outlets, c&#39;est choisir la richesse.</span>
</p>
<p style="margin-top:0;margin-right:0;margin-bottom:14px;margin-left:0;padding:0 0 0 0 ;vertical-align:baseline;background:rgb(255,255,255)">
    <span style="color: rgb(44, 62, 80);font-size: 18px">D&#39;accord, ce qui précède est l&#39;introduction à l&#39;entreprise, commencez votre parcours entrepreneurial et agissez rapidement&nbsp;!</span>
</p>
<p style="margin-top:5px;margin-right:0;margin-bottom:5px;margin-left:0">
    <span style=";font-family:Calibri;font-size:16px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:宋体;font-size:14px">&nbsp;</span>
</p>
<p>
    <br/>
</p>`
